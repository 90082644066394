import Dashboard from "./Dashboard";


export default function DashboardManager() {

    return(
            <Dashboard />
    )


}