import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../../util/authConfig";
import { ThreeDots } from "react-loader-spinner";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import FilterSingleSelect from "../../components/FilterSingleSelect";
import FilterSingleWithLabels from "../../components/FilterSingleWithLabels";

import * as dfd from 'danfojs'
import { Button } from "react-bootstrap";
import { json } from "react-router-dom";
import moment from "moment/moment";
import { readString } from "react-papaparse";


// import InboundOverview from "./InboundOverview";


export default function ProductBatchStatus() {


    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();

    const [loading, setLoading] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState([])
    const [checkboxIncludeEmptyRecords, setCheckboxIncludeEmptyRecords] = useState(false)

    const [inboundDeliveryStockStatus, setInboundDeliveryStockStatus] = useState([]);
    const [quantityReceivedSum, setQuantityReceivedSum] = useState(0)
    const [quantityOnStockSum, setQuantityOnStockSum] = useState(0)
    const [price_per_quantity_in_foreign_currencyAverage, setPrice_per_quantity_in_foreign_currencyAverage] = useState(0)
    const [foriegn_exchange_rateAverage, setForiegn_exchange_rateAverage] = useState(0)
    const [price_nokAverage, setPrice_nokAverage] = useState(0)
    const [value_receivedSum, setValue_receivedSum] = useState(0)
    const [value_on_stockSum, setValue_on_stockSum] = useState(0)
    const filterByFields = ['backstube_name', 'article_nr', 'gtin'];
    const filterByLabels = ['Name', 'Article nr.', 'gtin'];
    const [products, setProducts] = useState()
    const [suppliers, setSuppliers] = useState();
    const [yesterday, setYesterday] = useState(() => {
        let d = new Date();
        d.setDate(d.getDate()-1);
        return d;
    })
    const [today, setToday] = useState(new Date());
    const [chosenDate, setChosenDate] = useState(moment(yesterday).format("YYYY-MM-DD")); // Default should be yesterday
    const [valuta, setValuta] = useState();
    const [valutaArray, setValutaArray] = useState();

    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])

    useEffect(() => {
        setLoading(false);
    }, [token])



    useEffect(() => {
        if(!token) return;
        setLoading(true);
        fetch("./api/GetAllProducts", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json()
            }
        })
        .then(data => {
            setProducts(data);
            console.log(data)
        })
        .catch(err => {
            console.log(err);
        })
        
        fetch("./api/GetAllsuppliers", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json()
            }
        })
        .then(data => {
            setSuppliers(data);
            console.log(data)
        })
        .catch(err => {
            console.log(err);
        })

        fetch("https://data.norges-bank.no/api/data/EXR/B.USD+EUR+SEK+DKK+GBP.NOK.SP?format=csv&startPeriod=2019-12-31&endPeriod="+today.toISOString()+"&locale=no&bom=include")
        .then(response => {
            if(response.ok) {
                return response.text();
            }
        })
        .then(data => {
            setValuta(data);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
        })
    }, [token])


    useEffect(() => {
        if(!valuta) return;
        readString(valuta, {
            worker: true,
            complete: (results) => {
                setValutaArray(results.data)
            },
          });
    }, [valuta])


    useEffect(() => {
        if(!products) return;
        setLoading(false);
    }, [products])


    const findExchangeRate = (currency, date) => {
        if(!date) return;
        if(!valutaArray) return;
        if(!currency) return;
        if(currency == "N/A") return;
        if(currency == "NOK") return 1;

        let d = moment(date, "YYYY-MM-DD");

        if(d.isoWeekday() == 6) {
            d.subtract(1, "days")
        } else if(d.isoWeekday() == 7) {
            d.subtract(2, "days");
        }

        let rate;
        try {
            rate = valutaArray.find(o => o.includes(d.format("YYYY-MM-DD")) && o.includes(currency))[15];
            
        } catch (error) {
            console.log("Did not find rate for " + currency + " on " + date);
        }
        let i = 0;
        while(rate == null) {
            d.subtract(1, "days");
            try {
                rate = valutaArray.find(o => o.includes(d.format("YYYY-MM-DD")) && o.includes(currency))[15];
            } catch {}
            i++;
            if(i > 3) return -1;
        }
        rate = rate.replace(/,/, '.')
        rate = parseFloat(rate)
        if(currency == "SEK" || currency == "DKK") {
            rate = rate / 100;
        }
        return rate;
    }

    const formatDate = (dateString) => {
        return dateString;
    }


    function handleDate(event){
        const {value} = event.target;
        setChosenDate(value);
        if (selectedProduct.length > 0) {
            getInboundDeliveryStockStatus(selectedProduct[0].id, value, checkboxIncludeEmptyRecords)
        }

    }

    function handleProductChange(return_data) {
        const value = return_data;
        setSelectedProduct(value)
        if(value.length > 0) {
            console.log(chosenDate)
            console.log(value[0].id)
            getInboundDeliveryStockStatus(value[0].id, chosenDate, checkboxIncludeEmptyRecords)
        }
    }

    function handleCheckboxIncludeEmptyRecordsChange() {
        setCheckboxIncludeEmptyRecords((checkboxIncludeEmptyRecords) => !checkboxIncludeEmptyRecords)
    }

    function handleExportInboundDeliveries() {
        tableToCSV();
        //console.log(inboundDeliveryStockStatus)
    }



    function tableToCSV() {
        var csv_data = [];
        const table = document.querySelector("#status");
        var rows = table.getElementsByTagName('tr');
        for (var i = 1; i < rows.length; i++) {
     
            var cols = rows[i].querySelectorAll('td,th');
            var csvrow = [];
            for (var j = 0; j < cols.length; j++) {
                if(i == 4 || i == 5) {
                csvrow.push(cols[j].innerHTML.toUpperCase());
                continue;
                }
                csvrow.push(cols[j].innerHTML);
            }
            csv_data.push(csvrow);
        }

        let columns = [ "Delivery date", "Quantity Received", "Quantity On stock", "Price", "Currency", "FX rate", "Invoice date", "Price, NOK", "Value Received", "Value On stock" ]
        let tensor_arr = dfd.tensorflow.tensor2d(csv_data.slice(1, csv_data.length))
        let df = new dfd.DataFrame(tensor_arr, {columns: columns})

        dfd.toExcel(df, {fileName: moment(chosenDate).format("YYYY-MM-DD") + " - " + selectedProduct[0].article_nr + " - " + selectedProduct[0].name + " - " + selectedProduct[0].gtin + ".xlsx"})
        // csv_data = csv_data.join('\n');



        //downloadCSVFile(csv_data);
    }

    
function downloadCSVFile(csv_data) {
 
    let CSVFile = new Blob([csv_data], { type: "text/csv" });
 
    var temp_link = document.createElement('a');
    temp_link.download = "GfG.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
}

    

    
    function getInboundDeliveryStockStatus(product_id, date){
        console.log(product_id, date);
        let data = {
            "product_id": product_id,
            "date": date
        }
        fetch("./api/GetStockStatusByProductAndDate", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
        })
        .then(data => {
            setInboundDeliveryStockStatus([...data]);
            console.log(data);
        })
        
    }

    useEffect(() => {
        if(inboundDeliveryStockStatus.length == 0) return;
        console.log(inboundDeliveryStockStatus.length);

        let quantityReceivedValues = [];
        let quantityOnStockValues = [];
        let priceValues = [];
        let fxRateValues = [];
        let priceInNokValues = [];
        let receivedValueSumValues = [];
        let onStockSumValues = [];

        inboundDeliveryStockStatus.forEach(row => {
            quantityReceivedValues.push(parseInt(row.line.quantity_pce_received));
            quantityOnStockValues.push(parseInt(row.status.quantityLeft));
            if(row.line.price > 0) priceValues.push(parseFloat(row.line.price));
            if(row.line.invoice_date && row.line.currency) {
                let fx_rate = findExchangeRate(row.line.currency, formatDate(row.line.invoice_date));
                fxRateValues.push(fx_rate);
                let priceInNok = fx_rate * row.line.price;
                priceInNokValues.push(priceInNok);
                let recSum = priceInNok * row.line.quantity_pce_received;
                receivedValueSumValues.push(recSum);
                let stockSum = priceInNok * row.status.quantityLeft;
                onStockSumValues.push(stockSum);
            }
        })


        if(quantityReceivedValues.length > 0) setQuantityReceivedSum(sumValues(quantityReceivedValues));
        if(quantityOnStockValues.length > 0) setQuantityOnStockSum(sumValues(quantityOnStockValues));
        if(priceValues.length > 0) setPrice_per_quantity_in_foreign_currencyAverage(getAverage(priceValues));
        if(fxRateValues.length > 0) setForiegn_exchange_rateAverage(getAverage(fxRateValues));
        if(priceInNokValues.length > 0) setPrice_nokAverage(getAverage(priceInNokValues));
        if(receivedValueSumValues.length > 0) setValue_receivedSum(sumValues(receivedValueSumValues));
        if(onStockSumValues.length > 0) setValue_on_stockSum(sumValues(onStockSumValues));

        
    }, [inboundDeliveryStockStatus])


    function sumValues(values) {
        let sum = values.reduce((a, b) => a+b);
        return sum;
    }

    function getAverage(values) {
        let length = values.length;
        let average = values.reduce((a, b) => a+b);
        average = average/length;
        return average;
    }


    if(loading) {
        return(
            <div className="container vh-100 d-flex justify-content-center align-items-center">
                <ThreeDots 
                    height="80" 
                    width="80" 
                    radius="9"
                    color="#3e3e3e" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                    />
            </div>
        )
    }


        return(
            <>
            <Container style={{background: '#C7B9A7', "border-radius": '10px' }} className="px-4 py-4 mt-5">
                <Row>
                    <h2 className="mb-4">Product batch status</h2>
                    {products &&
                    <Col>
                        <FilterSingleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedProduct} complete_object={products} onChange={(return_data) => handleProductChange(return_data)}></FilterSingleWithLabels>
                        <Form className="mt-2">
                            
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id="default-checkbox"
                                label="Include empty records"
                                defaultChecked={checkboxIncludeEmptyRecords}
                                onChange={() => handleCheckboxIncludeEmptyRecordsChange()}
                                // onChange={() => setCheckboxIncludeEmptyRecords((checkboxIncludeEmptyRecords) => !checkboxIncludeEmptyRecords)}
                            />
                        </Form>
                    </Col>
                    }
                    <Col>
                        <input onChange={handleDate} value={chosenDate} type="date" className="form-control" id="start" name="trip-start"/>
                        

                    </Col>
                    
                </Row>
            </Container>

            {selectedProduct.length > 0 &&
                <Container className="mt-5 px-2">
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Product information</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{selectedProduct[0].name}</td>
                                            </tr>
                                            <tr>
                                                <td>Art. nr.</td>
                                                <td>{selectedProduct[0].article_nr}</td>
                                            </tr>
                                            <tr>
                                                <td>GTIN</td>
                                                <td>{selectedProduct[0].gtin}</td>
                                            </tr>
                                            <tr>
                                                <td>Supplier</td>
                                                <td>{suppliers.find(s => s.id == selectedProduct[0].supplier).name}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <h3>Status on inbound deliveries</h3>
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <Button onClick={handleExportInboundDeliveries} variant="secondary">Export</Button>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Table id="status">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style={{background: '#f0f0f0'}} className="text-center" colSpan="2">Quantity</th>
                                    <th colspan="5"></th>
                                    <th style={{background: '#f0f0f0'}} className="text-center" colspan="2">Value, NOK</th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th>Delivery date</th>
                                    <th style={{background: '#f0f0f0'}} className="text-center">Received</th>
                                    <th style={{background: '#f0f0f0'}} className="text-center">On stock</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Currency</th>
                                    <th className="text-center">FX rate</th>
                                    <th className="text-center">Invoice date</th>
                                    <th className="text-center">Price, NOK</th>
                                    <th style={{background: '#f0f0f0'}} className="text-center">Received</th>
                                    <th style={{background: '#f0f0f0'}} className="text-center">On stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*inboundDeliveryStockStatus &&
                                    inboundDeliveryStockStatus.filter(row => checkboxIncludeEmptyRecords || row.quantity_on_stock > 0 ).map(row => {
                                        return (
                                            <tr>
                                               <td className="text-center">{ row.delivery_date }</td>
                                                <td style={{background: '#f0f0f0'}} className="text-center">{ row.quantity_received.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                                <td style={{background: '#f0f0f0'}}  className="text-center">{ row.quantity_on_stock.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                                <td className="text-center">{ row.price_per_quantity_in_foreign_currency.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                                <td className="text-center">{ row.currency }</td>
                                                <td className="text-center">{ row.foreign_exchange_rate.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                                <td className="text-center">{ row.price_nok.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                                <td style={{background: '#f0f0f0'}}  className="text-center">{ row.value_received.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                                <td style={{background: '#f0f0f0'}}  className="text-center">{ row.value_on_stock.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                            </tr>
                                        )
                                    })
                                */}
                                {inboundDeliveryStockStatus &&
                                    inboundDeliveryStockStatus.sort((b,a) => a.line.id - b.line.id).map(row => {
                                        if(!row.line.invoice_date && !checkboxIncludeEmptyRecords) return;
                                        return(
                                            <tr>
                                                <td className="text-center">{formatDate(row.delivery.date_of_delivery)}</td>
                                                <td style={{background: '#f0f0f0'}} className="text-center">{ row.line.quantity_pce_received.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                                <td style={{background: '#f0f0f0'}}  className="text-center">{ row.status.quantityLeft.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                                <td className="text-center">{ row.line.price ? row.line.price.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) : 0 }</td>
                                                <td className="text-center">{ row.line.currency ? row.line.currency : "N/A"}</td>
                                                <td className="text-center">{ row.line.invoice_date ? findExchangeRate(row.line.currency, formatDate(row.line.invoice_date)).toLocaleString(undefined, {maximumFractionDigits:2}) : "N/A" }</td>
                                                <td className="text-center">{ row.line.invoice_date ? formatDate(row.line.invoice_date) : "N/A"}</td>
                                                <td className="text-center" id="pricePerNok">{ row.line.invoice_date ? parseFloat(findExchangeRate(row.line.currency, formatDate(row.line.invoice_date)) * row.line.price).toLocaleString(undefined, {maximumFractionDigits:2}) : "N/A"}</td>
                                                <td style={{background: '#f0f0f0'}} className="text-center">{ row.line.invoice_date ? parseFloat((findExchangeRate(row.line.currency, formatDate(row.line.invoice_date)) * row.line.price) * parseFloat(row.line.quantity_pce_received)).toLocaleString(undefined, {maximumFractionDigits:2}) : "N/A" }</td>
                                                <td style={{background: '#f0f0f0'}} className="text-center">{ row.line.invoice_date ? parseFloat((findExchangeRate(row.line.currency, formatDate(row.line.invoice_date)) * row.line.price) * parseFloat(row.status.quantityLeft)).toLocaleString(undefined, {maximumFractionDigits:2}) : "N/A" }</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                            <tfoot>
                                {/* 
                                <tr>
                                    <td className="text-center"></td>
                                    <td className="text-center">{ quantityReceivedSum.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td className="text-center">{ quantityOnStockSum.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td className="text-center">{ price_per_quantity_in_foreign_currencyAverage.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">{ foriegn_exchange_rateAverage.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                    <td className="text-center">{ price_nokAverage.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                    <td className="text-center">{ value_receivedSum.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td className="text-center">{ value_on_stockSum.toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                </tr>
                                */}
                                { inboundDeliveryStockStatus.length > 0 && 
                                <tr>
                                    <td className="text-center">-</td>
                                    <td style={{background: '#f0f0f0'}} className="text-center">{ quantityReceivedSum }</td>
                                    <td style={{background: '#f0f0f0'}} className="text-center">{ quantityOnStockSum }</td>
                                    <td className="text-center">{ parseFloat(price_per_quantity_in_foreign_currencyAverage).toFixed(2) }</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">{ parseFloat(foriegn_exchange_rateAverage).toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">{ parseFloat(price_nokAverage).toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td style={{background: '#f0f0f0'}} className="text-center">{ parseFloat(value_receivedSum).toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                    <td style={{background: '#f0f0f0'}} className="text-center">{ parseFloat(value_on_stockSum).toLocaleString(undefined, {maximumFractionDigits:2}) }</td>

                                </tr>
                                }
                            </tfoot>
                        </Table>
                    </Row>
                </Container>
            }
            </>
        ); 
}