import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import NavigationBar from "../../modules/NavigationBar";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import FilterMultipleWithLabels from "../../components/FilterMultipleWithLabels";


export default function CustomerPrices() {

    const [error, setError] = useState([]); // for front end input validation

    const [createRelationPrice, setCreateRelationPrice] = useState()
    const [createRelationChosenDate, setCreateRelationChosenDate] = useState()
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [selectedCreateCustomers, setSelectedCreateCustomers] = useState([])
    const [selectedCreateProducts, setSelectedCreateProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [customerPrices, SetCustomerPrices] = useState()
    const [customers, SetCustomers] = useState();
    const [products, SetProducts] = useState();
    const [contactPersons, SetContactPersons] = useState();
    const [selectedElement, SetSelectedElement] = useState();
    const [selectedElementType, SetSelectedElementType] = useState();
    const [isCreateMode, SetIsCreateMode] = useState(false);
    const [refresh, SetRefresh] = useState();
    const filterByFields = ['backstube_name', 'article_nr', 'gtin'];
    const filterByLabels = ['Name', 'Article nr.', 'gtin'];
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();


    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    function validateNumber(type, name, inpValue){ // for front end input validation
        let validateOK = false;
        if (type === 'int'){
            if (!isNaN(inpValue)){
                //if (inpValue.toString().indexOf('.') === -1){
                if ((Math.round(inpValue) - inpValue) == 0){
                    validateOK = true;
                }
            }
        }
        else{
            if (!isNaN(inpValue)){
                validateOK = true;
            }
        }
                

        if (validateOK){
            if (error.includes(name)){                
                setError(error.filter(errorName => errorName !== name));
            }
        }
        else{
            if (!error.includes(name)){
                setError(prevError => [...prevError, name]);
            }
            else{console.log("I LISTEN: " + name);}
        }
    }
    const errorMessageStyling = {
        color: "red",
        margin: 0,
        padding: 0
    }

    function handleCreateRelationChosenDate(event){
        const {value} = event.target;
        setCreateRelationChosenDate(value);
    }
    function handleUpdateCreateRelationPrice(event) {
        const {value} = event.target;   
        const validateValue = /^\d*\.?\d*$/; // decimal number regex
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        setCreateRelationPrice(value)
    }

    function validateCreate(){
        console.log(selectedCreateCustomers)
        console.log(selectedCreateCustomers.length)
        if(selectedCreateCustomers.length == 0 || selectedCreateCustomers[0].id === undefined) {
            return false;
        };
        if(selectedCreateProducts.length == 0 || selectedCreateProducts[0].id === undefined) {return false};
        if(createRelationChosenDate == "" || createRelationChosenDate === undefined) {return false};
        if(createRelationPrice === undefined) {return false};
        console.log("true");
        return true;
    }

    function constructAPIPayload(){
        var payload = []
        for (var customer in selectedCreateCustomers) {
            for (var product in selectedCreateProducts) {
                var item = {
                    "customer": selectedCreateCustomers[customer].id,
                    "product": selectedCreateProducts[product].id,
                    "start_date": createRelationChosenDate,
                    "net_quantity_price": createRelationPrice.toString(),
                    "active": 1
                }
                payload.push(item)
            }
        }
        return payload
    }

    function handleCreateNewRelation(){
        fetch("./api/CreateMultipleCustomerPrices", {
            method: "POST",
            body: JSON.stringify(constructAPIPayload()),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(!response.ok) {
                SetToastIsError(true);
            } else {
                SetToastIsError(false);
            }
            return response.text();   
        })
        .then((data) => {
            SetRefresh(Math.random()*500000);
            SetToastMessage(data);
            setShowToast(true);
            handleClose();
        })
    }

    useEffect(() => {
        if(!token) return;

        fetch("./api/GetAllCustomerPrices",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetCustomerPrices(data);
        });

        fetch("./api/GetAllCustomers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetCustomers(data);
        });

        fetch("./api/GetAllProducts",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetProducts(data);
        });

        fetch("./api/GetAllContactPersons",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetContactPersons(data);
        });

    }, [token, refresh]);


    const handleClick = (element, type) => {
        SetModalErrorMsg(null);
        SetIsCreateMode(false);
        SetSelectedElementType(type);
        SetSelectedElement(element);
        setShow(true);
    }

    const handleUpdate = () => {

    }
    const handleSend = () => {
        let element;
        let url;
        let valid = true;
        if(!isCreateMode) {
            switch(selectedElementType) {
                case "CUSTOMERPRICE":
                    element = {
                        "id": selectedElement.id,
                        "start_date": document.getElementById("date").value ? document.getElementById("date").value : selectedElement.name,
                        "active": document.getElementById("active").value ? document.getElementById("active").value : selectedElement.active
                    }
                    url = "./api/UpdateCustomerPrice";
                    console.log(element);
                    break;
            }
        } else {
            switch(selectedElementType) {
                case "CUSTOMERPRICE":
                    var test = validateCreate();
                    if (!test){
                        console.log(validateCreate);
                        valid = false;
                    } else {
                        return handleCreateNewRelation()

                    }
                    break;
            }
        }
        if(valid) {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(element),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                if(!response.ok) {
                    SetToastIsError(true);
                } else {
                    SetToastIsError(false);
                }
                return response.text();   
            })
            .then((data) => {
                SetRefresh(Math.random()*500000);
                SetToastMessage(data);
                setShowToast(true);
                handleClose();
            })
            console.log("Sent update to backend")
            
        } else {
            console.log("not valid");
            SetModalErrorMsg("One or more fields are not valid!");
        }
    }

    const handleCreate = (type) => {
        SetModalErrorMsg(null);
        SetSelectedElement(null);
        console.log(type)
        SetIsCreateMode(true);
        setShow(true);
        SetSelectedElementType(type);
    }

    return(
        
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex align-items-center">
                        <h2 className="my-0">Customer Prices</h2>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <button className="btn btn-success my-2 mx-2" onClick={() => handleCreate("CUSTOMERPRICE")}>Create new price rule</button>
                    </Col>
                </Row>
                    <Row className="mt-4">
                    <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="customer" selected={selectedCustomers} complete_object={customers} onChange={(return_data) => setSelectedCustomers(return_data)}></FilterMultipleSelect>    
                        <div className="mt-1">
                            <FilterMultipleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)} />
                        </div>
                    </Card.Header>
                    <Card.Body className="p-0 m-0">
                    {customerPrices &&
                        <Table striped bordered hover className="m-0">
                            <thead>
                                <tr>
                                    {/* <th className="text-center fw-bold">ID</th> */}
                                    <th className="px-2 fw-bold">Customer</th>
                                    <th className="px-2 fw-bold text-center">Art. nr</th>
                                    <th className="px-2 fw-bold">Product</th>
                                    <th className="text-center fw-bold">Start date</th>
                                    <th className="text-center fw-bold">Net price</th>
                                    <th className="fw-bold">Active</th>
                                    {/* <th className="text-center fw-bold">Status</th> */}
                                </tr>
                            </thead>
                            <tbody style={{"cursor": "pointer"}}>
                                {customerPrices.filter(function (s) {
                                        if(selectedCustomers.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sc in selectedCustomers) {
                                                if (selectedCustomers[sc].id == s.customer[0].id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }).filter(function (s) {
                                        if(selectedProducts.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sp in selectedProducts) {
                                                if (selectedProducts[sp].id == s.product[0].id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }).map(i => {
                                    return(
                                        <tr key={i.id} onClick={() => handleClick(i, "CUSTOMERPRICE")}>
                                            {/* <td className="text-center">{i.id}</td> */}
                                            <td className="px-2">{i.customer[0].name}</td>
                                            <td className="px-2 text-center">{i.product[0].article_nr}</td>
                                            <td className="px-2">{i.product[0].name}</td>
                                            <td className="text-center">{i.start_date}</td>
                                            <td className="px-2 text-end">{i.net_quantity_price}</td>
                                            <td className="text-center">{i.active == 1 && <Badge pill bg="success">Active</Badge>}{i.active == 0 && <Badge pill bg="secondary">Inactive</Badge>}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    </Card.Body>
                </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {selectedElement && selectedElementType == "CUSTOMERPRICE" &&
                    <>
                        <label for="customer">Customer</label>
                        <input disabled id="customer" className="form-control" type="text" placeholder={selectedElement.customer[0].name} />
                        <label for="product">Product</label>
                        <input disabled id="product" className="form-control" type="text" placeholder={selectedElement.product[0].name} />
                        <label for="price">Net price</label>
                        <input disabled id="price" className="form-control" type="number" placeholder={selectedElement.net_quantity_price} />
                        <label for="date">Start date</label>
                        <input id="date" className="form-control" type="date" placeholder={selectedElement.start_date} />
                        
                        <label for="active">Status</label>
                        <input id="active" className="form-control" type="text" placeholder={selectedElement.active} onChange={(value) => validateNumber("int", "status", value.target.value)}/>
                        {error.includes("status") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                   
                    </>
                    }
                   
                    
                    {isCreateMode && selectedElementType == "CUSTOMERPRICE" &&
                    <>
                        <label for="product">Product(s)</label>
                        <FilterMultipleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedCreateProducts} complete_object={products} onChange={(return_data) => setSelectedCreateProducts(return_data)} />

                        <label for="customer">Customer(s)</label>
                        <FilterMultipleSelect name_of_filtered="customer" selected={selectedCreateCustomers} complete_object={customers} onChange={(return_data) => setSelectedCreateCustomers(return_data)}></FilterMultipleSelect>    

                        <label for="date">Start date of new price</label>
                        <input onChange={handleCreateRelationChosenDate} type="date" className="form-control" id="date" name="date"
                        value={createRelationChosenDate}                                        
                        />
                        <label for="price">Net price</label>
                        <input onChange={(event) => handleUpdateCreateRelationPrice(event)} type="number" className="form-control" id="price" name="price"
                        value={createRelationPrice}                                        
                        />
                   
                       
                        
                    </>
                    }
                    
                
                    {modalErrorMsg &&
                    <p className="text-danger">{modalErrorMsg}</p>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSend}>Save</Button>
                    </Modal.Footer>
            </Modal>
        
        </Row>
    </Container>   
                
    </AuthenticatedTemplate>
       </div>
    )
}