import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../../util/authConfig";
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import FilterSingleWithLabels from "../../components/FilterSingleWithLabels";
import { CartesianGrid, LineChart, XAxis, YAxis, Line, ReferenceLine } from "recharts";
import { ThreeDots } from "react-loader-spinner";
import { Tooltip }  from "recharts";
import moment from "moment";



export default function PriceHistory() {
    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();

    // Data holders
    const [customers, SetCustomers] = useState();
    const [products, SetProducts] = useState();
    const [selectedCustomer, SetSelectedCustomer] = useState();
    const [selectedProduct, SetSelectedProduct] = useState();
    const [collectionPrices, SetCollectionPrices] = useState();
    const [collectionPricesToDraw, SetCollectionPricesToDraw] = useState();
    const [average, SetAverage] = useState(0);

    // Misc
    let CustomerFilterLabels = ["Name", "Org nr."];
    let CustomerFilterFields = ["name", "orgnr"];

    let ProductFilterLabels = ["Name", "GTIN"];
    let ProductFilterFields = ["backstube_name", "gtin"];

    const [startDate, SetStartDate] = useState();
    const [endDate, SetEndDate] = useState();
    const [activeDate, SetActiveDate] = useState();

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    useEffect(() => {
        if(!token) return;

        fetch("./api/GetAllCustomers", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) return response.json();
        })
        .then(data => {
            SetCustomers(data);
        })
        .catch(err => {
            console.log("Error fetching customers.");
            console.log(err)
        })
    }, [token])

    useEffect(() => {
        if(!customers) return;

        fetch("./api/GetAllProducts", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) return response.json();
        })
        .then(data => {
            SetProducts(data);
            setLoading(false);
        })
        .catch(err => {
            console.log("Error fetching products.");
            console.log(err)
        })
    }, [customers])





    // Functions
    function formatDateToYYYYMMDD(dateString) {
        console.log(dateString)

        const formats = [
            { format: /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/, order: ['DD', 'MM', 'YYYY', 'HH', 'mm', 'ss'] },
            { format: /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (\d{2})/, order: ['MM', 'DD', 'YYYY', 'HH', 'mm', 'ss', 'tt'] },
            { format: /(\d{1})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2}) (\d{2})/, order: ['MM', 'DD', 'YYYY', 'HH', 'mm', 'ss', 'tt'] },
            { format: /(\d{4})\-(\d{2})\-(\d{2})/, order: ['YYYY', 'MM', 'DD'] }
        ];

        if(formats[2].format.test(dateString)) return dateString;
        
        let matchedFormat = null;
        for (const format of formats) {
            if (format.format.test(dateString)) {
            matchedFormat = format;
            break;
            }
        }
        
        if (!matchedFormat) {
            throw new Error('Invalid date format. Supported formats are "DD.MM.YYYY HH:mm:ss" and "MM/DD/YYYY HH:mm:ss".');
        }
        
        const [, ...matches] = dateString.match(matchedFormat.format);
        const dateObject = {};
        
        for (let i = 0; i < matchedFormat.order.length; i++) {
            dateObject[matchedFormat.order[i]] = matches[i];
        }
        
        const { YYYY, MM, DD } = dateObject;
        return dateString;
    }


    const Search = () => {
        let averageQuery = {
            "id": selectedProduct[0].id
        }
        fetch("./api/GetCompanyWideAveragePriceForProduct", {
            method: "POST",
            body: JSON.stringify(averageQuery),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) return response.json();
        })
        .then(data => {
            SetAverage(data);
            console.log("average: ", data)
        })
        .catch(err => {
            console.log("Error fetching collection.");
            console.log(err)
        })

        let data = {
            "customer_id": selectedCustomer[0].id,
            "product_id": selectedProduct[0].id
        }
        fetch("./api/GetCustomerPricesForCustomerProductCollection", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) return response.json();
        })
        .then(data => {
            console.log(startDate)
            data.forEach(d => {
                d.start_date = formatDateToYYYYMMDD(d.start_date);
            })
            data.sort((a,b) => moment(a.start_date, "YYYY-MM-DD").unix() - moment(b.start_date, "YYYY-MM-DD").unix());
            SetCollectionPrices([...data]);
            SetCollectionPricesToDraw([]);
        })
        .catch(err => {
            console.log("Error fetching collection.");
            console.log(err)
        })
    }


    useEffect(() => {
        if(!collectionPrices) return;
        if(!startDate || !endDate) {
            SetCollectionPricesToDraw(collectionPrices);
            return;
        }
        let toDraw = [];
        collectionPrices.forEach(d => {
            if(moment(d.start_date, "YYYY-MM-DD").unix() >= moment(startDate).unix() && moment(d.start_date, "YYYY-MM-DD").unix() <= moment(endDate).unix()) {
                toDraw.push(d);
            }
        })
        toDraw.sort((a,b) => moment(a.start_date, "YYYY-MM-DD").unix() - moment(b.start_date, "YYYY-MM-DD").unix())
        SetCollectionPricesToDraw(toDraw)
        console.log("BASE", collectionPrices)
        console.log("TO DRAW", collectionPricesToDraw)
    }, [startDate, endDate, collectionPrices])

    useEffect(() => {
        if(!collectionPricesToDraw) return;
        let today = moment(new Date().getTime());
        let smallestSpan = Number.MAX_VALUE;
        let nearestDate;
        collectionPricesToDraw.forEach(d => {
            let dt = moment(d.start_date, "YYYY-MM-DD");
            if(today.unix() > dt.unix() && (today.unix() - dt.unix()) < smallestSpan) {
                smallestSpan = today.unix() - dt.unix();
                nearestDate = dt;
            }
        })
        if(!nearestDate) return;
        SetActiveDate(nearestDate.format("YYYY-MM-DD"))
    }, [collectionPricesToDraw])


    const updateStartDate = () => {
        let value = document.getElementById("startDate").value;
        if(!value) return;
        console.log(value)
        SetStartDate(value);
        
    }

    const updateEndDate = () => {
        let value = document.getElementById("endDate").value;
        if(!value) return;
        console.log(value)
        SetEndDate(value);
        
    }


    function ExportToCSV() {
        if(!collectionPrices) return;
        let csv = "ACTIVE,DATE,PRODUCT NAME,GTIN,PRICE";
        collectionPrices.forEach(c => {
            csv += `\n${c.active},${c.start_date},${selectedProduct[0].backstube_name},${selectedProduct[0].gtin},${c.net_quantity_price}`
        })

        downloadCSVFile(csv);
    }

    function downloadCSVFile(csv_data) {
        let CSVFile = new Blob([csv_data], { type: "text/csv" });
        
        var temp_link = document.createElement('a');
        temp_link.download = `${selectedCustomer[0].name}-${selectedProduct[0].gtin}-pricehistory.csv`;
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    function getMaxValue() {
        let maxVal = -10000;
        collectionPrices.forEach(c => {
            if(c.net_quantity_price > maxVal) maxVal = c.net_quantity_price;
        })
        return maxVal;
    }


    if(loading) {
        return(
            <div className="container vh-100 d-flex justify-content-center align-items-center">
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#3e3e3e" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                        />
                </div>
        )
    }

    return(
        <div className="container-fluid d-flex justify-content-center align-items-center flex-column">
            <h2>Price History</h2>
            <div className="col-4 m-5 d-flex justify-content-center align-items-center flex-column text-center">
                <p>Please select a customer</p>
                <div className="col-12 m-2">
                    <FilterSingleWithLabels filterByFields={CustomerFilterFields} filterByLabels={CustomerFilterLabels} name_of_filtered="Customer" selected={selectedCustomer} complete_object={customers} onChange={(return_data) => SetSelectedCustomer(return_data)}></FilterSingleWithLabels>
                </div>
                <div className="col-12 m-2">
                    <FilterSingleWithLabels filterByFields={ProductFilterFields} filterByLabels={ProductFilterLabels} name_of_filtered="Product" selected={selectedProduct} complete_object={products} onChange={(return_data) => SetSelectedProduct(return_data)}></FilterSingleWithLabels>
                </div>
                <div className="col-12 m2 d-flex">
                    <div className="col-6">
                        <p className="text-secondary">Start</p>
                        <input type="date" className="form-control" id="startDate" onChange={updateStartDate}/>
                    </div>
                    <div className="col-6">
                        <p className="text-secondary">End</p>
                        <input type="date" className="form-control" id="endDate" onChange={updateEndDate}/>
                    </div>
                </div>
                <div className="col-12 m-2">
                    <button className="btn btn-dark m-2" onClick={Search}>Search</button>
                    {collectionPrices &&
                        <button className="btn btn-dark m-2" onClick={ExportToCSV}>Export</button>
                    }
                </div>
            </div>
            {collectionPricesToDraw && collectionPricesToDraw.length > 0 &&
                <LineChart key={collectionPricesToDraw.length+3} width={1400} height={500} data={collectionPricesToDraw} margin={{ top: 5, right: 20, bottom: 5, left: 5 }}>
                    <Line key={collectionPricesToDraw.length+4} type="monotone" dataKey="net_quantity_price" stroke="#044866" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis key={collectionPricesToDraw.length+5} dataKey="start_date" />
                    <YAxis key={collectionPricesToDraw.length+6} dataKey="net_quantity_price" domain={[0, parseInt(Math.max(average, getMaxValue())) + 50]}/>
                    <ReferenceLine
                        y={average}
                        label={{ value: `Todays company wide average: ${average.toFixed(2)}`, position: "center"}}
                        stroke="red"
                        strokeDasharray="50 50"
                    />
                    <ReferenceLine
                        x={activeDate}
                        label={{ value: `Currently Active`, position: "center"}}
                        stroke="green"
                        strokeDasharray="5 5"
                    />
                    <Tooltip />
              </LineChart>
            }
            {collectionPricesToDraw && collectionPricesToDraw.length == 0 &&
                <p className="text-secondary">No price history for the selected pair.</p>
            }
        </div>
    )
}