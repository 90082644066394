import { useEffect } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export default function OrderListElement(props) {
    
    const dfd = require("danfojs")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const exportTripletex = () => {
        var data = []
        for (var line in props.o.lines) {
            var lineData = {
                "ORDER NO": "OP" + props.o.id.toString(), 
                "ORDER DATE": props.o.date_of_order.split(" ")[0],
                "CUSTOMER NO": props.o.sub_customer[0].tripletexId,
                "DELIVERY DATE": props.o.date_of_pickup.split(" ")[0],
                "ORDER LINE - DESCRIPTION": props.o.lines[line].product.backstubeName,
                "ORDER LINE - UNIT PRICE": props.o.lines[line].netLinePrice / props.o.lines[line].quantity / props.o.lines[line].product.piecesPerQuantity,
                "ORDER LINE - COUNT": props.o.lines[line].actualQuantity * props.o.lines[line].product.piecesPerQuantity,
                "ORDER LINE - VAT CODE": props.o.lines[line].product.vatCode
            }
            data.push(lineData)
        }
        var freightLine = {
            "ORDER NO": "OP" + props.o.id.toString(), 
            "ORDER DATE": props.o.date_of_order.split(" ")[0],
            "CUSTOMER NO": props.o.sub_customer[0].tripletexId,
            "DELIVERY DATE": props.o.date_of_pickup.split(" ")[0],
            "ORDER LINE - DESCRIPTION": "Frakt",
            "ORDER LINE - UNIT PRICE": 0,
            "ORDER LINE - COUNT": 1,
            "ORDER LINE - VAT CODE": 3
        }
        data.push(freightLine)
        var df = new dfd.DataFrame(data)
        df.print()
        const csv = dfd.toCSV(df, { fileName: "Order " + props.o.id.toString() + ".csv", download: true})
    }

    return(
        <AuthenticatedTemplate>
        <tr>
            <td className="text-center" onClick={handleShow}>{props.o.id}</td>
            <td className="text-center" onClick={handleShow}>{props.o.date_of_order.split(" ")[0]}</td>
            <td className="text-center" onClick={handleShow}>{props.o.date_of_pickup.split(" ")[0]}</td>
            <td className="px-2" onClick={handleShow}>{props.o.sub_customer[0].customerName}</td>
            <td className="px-2" onClick={handleShow}>{props.o.sub_customer[0].subCustomerName}</td>
            <td className="text-center" onClick={handleShow}>{props.o.lines.length}</td>
            <td className="text-center" onClick={exportTripletex}><Button variant="secondary">Export</Button></td>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Order {props.o.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Header>Order information</Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <tr>
                                    <td>Customer</td>
                                    <td>{props.o.sub_customer[0].customerName}</td>
                                </tr>
                                <tr>
                                    <td>Sub customer</td>
                                    <td>{props.o.sub_customer[0].subCustomerName}</td>
                                </tr>
                                <tr>
                                    <td>Order date</td>
                                    <td>{props.o.date_of_order.split(" ")[0]}</td>
                                </tr>
                                <tr>
                                    <td>Pick-up-date</td>
                                    <td>{props.o.date_of_pickup.split(" ")[0]}</td>
                                </tr>
                                <tr>
                                    <td>Net price</td>
                                    <td>{props.o.totalNetPrice}</td>
                                </tr>
                            </Table>
                        </Card.Body>
                    </Card>
                    {/* <h4>Order lines</h4> */}
                    <Card className="mt-3">
                        <Card.Header>
                            Order lines
                        </Card.Header>
                        <Card.Body>
                            <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th style={{background: '#f0f0f0'}} className="text-center" colspan="2">Boxes</th>
                                <th className="text-center" colspan="2">Units</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th>Line ID</th>
                                <th>Product</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">Ordered</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">Shipped</th>
                                <th className="text-center">Ordered</th>
                                <th className="text-center">Shipped</th>
                            </tr>
                        </thead>
                        
                            <tbody>
                                {props.o.lines.map((ol) => {
                                    return <tr key={ol.orderLineId}><td>{ol.orderLineId}</td><td>{ol.product.backstubeName}<br />GTIN: {ol.product.gtin}</td><td style={{background: '#f0f0f0'}} className="text-center">{ol.quantity}</td><td style={{background: '#f0f0f0'}} className="text-center">{ol.actualQuantity}</td><td className="text-center">{ol.product.piecesPerQuantity * ol.quantity}</td><td className="text-center">{typeof ol.actualQuantity == typeof 0 && ol.product.piecesPerQuantity * ol.actualQuantity}</td></tr>
                                })}
                                
                        


                            </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                    
                    {/* {JSON.parse(props.order['order_data'])["products"].map((e) => {
                        return <p className="inter-font m-0 p-0" key={Math.random() * 100000}>{JSON.stringify(e).replaceAll('"', "").replaceAll('{', "").replaceAll('}', "")}</p>
                    })} */}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </tr>
        </AuthenticatedTemplate>
    )
}