import { useMsal } from "@azure/msal-react";


export default function Logout() {

    const { instance } = useMsal();

    const lout = async () => {
        await instance.logout();
    }

    lout();

    return(
        <>
            "Logging out...";
        </>
    )

}