

//export default function ListElement(props) {
    //return(
        //<li className="list-group-item d-flex justify-content-start align-items-center">
           // <p className="m-0 flex-grow-1">{props.product.procured_product.backstube_name}</p>
            //<p className="px-2 m-0">{props.product.note_1}</p>
            //<p className="px-2 m-0">{props.product.note_2}</p>
            //<div className="col-2 col-sm-2 col-md-2 col-lg-1 d-flex justify-content-center align-items-center flex-column">
                //<input type="text" className="form-control text-center" value={props.amount} onChange={(event) => props.handleUpdate(props.product.procured_product.GTIN, event)} placeholder="#" aria-label="Number of boxes" />
            //</div>
            //<p className="px-2 m-0"><em>x {props.product.pieces_per_quantity}</em></p>
            //{props.natureOfCargo == "Frozen" &&
            //<p className="px-2 m-0"><em>{Math.round(1 / props.product.box_factor * props.amount * 100) / 100}</em></p>
            //}
        //</li>
    //)
//}

export default function ListElement(props) {
  return (
    
        <tr>
          <td className="px-2 text-center">{props.product.procured_product.article_nr}</td>
          <td className="px-2 text-left">{props.product.procured_product.backstube_name}</td>
          <td className="text-center">{props.product.note_1}</td>
          <td className="text-center" >{props.product.note_2}</td>
          <td className="text-center">
            <input
              type="text"
              className="form-control text-center"
              value={props.amount}
              onChange={(event) =>
                props.handleUpdate(
                  props.product.procured_product.GTIN,
                  event
                )
              }
              placeholder="#"
              aria-label="Number of boxes"
              
            />
          </td>
          <td className="text-center">
            <em>x {props.product.pieces_per_quantity}</em>
          </td>
          {props.natureOfCargo == "Frozen" && (
            <td className="text-center">
              <em>
                {Math.round(
                  (1 / props.product.box_factor) *
                    props.amount *
                    100
                ) / 100}
              </em>
            </td>
          )}
        </tr>
    
  );
}

  