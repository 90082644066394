import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import NavigationBar from "../../modules/NavigationBar";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";


export default function Suppliers() {


    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [suppliers, SetSuppliers] = useState()
    const [subCustomers, SetSubCustomers] = useState();
    const [contactPersons, SetContactPersons] = useState();
    const [selectedElement, SetSelectedElement] = useState();
    const [selectedElementType, SetSelectedElementType] = useState();
    const [isCreateMode, SetIsCreateMode] = useState(false);

    const [refresh, SetRefresh] = useState();
    const [error, setError] = useState([]);
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();

    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    useEffect(() => {
        if(!token) return;
        fetch("./api/GetAllSuppliers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetSuppliers(data);
        });

        fetch("./api/GetAllSubCustomers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetSubCustomers(data);
        });

        fetch("./api/GetAllContactPersons",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetContactPersons(data);
        });

    }, [token, refresh]);

    const errorMessageStyling = {
        color: "red",
        margin: 0,
        padding: 0
    }

    function validateNumber(type, name, inpValue){
        let validateOK = false;
        if (type === 'int'){
            if (!isNaN(inpValue)){
                //if (inpValue.toString().indexOf('.') === -1){
                if ((Math.round(inpValue) - inpValue) == 0){
                    validateOK = true;
                }
            }
        }
        else{
            if (!isNaN(inpValue)){
                validateOK = true;
            }
        }
                

        if (validateOK){
            if (error.includes(name)){                
                setError(error.filter(errorName => errorName !== name));
            }
        }
        else{
            if (!error.includes(name)){
                setError(prevError => [...prevError, name]);
            }
            else{console.log("I LISTEN: " + name);}
        }
    }

    const handleClick = (element, type) => {
        SetModalErrorMsg(null);
        SetIsCreateMode(false);
        SetSelectedElementType(type);
        SetSelectedElement(element);
        setShow(true);
    }

    const handleSend = () => {
        let element;
        let url;
        let valid = true;
        if(!isCreateMode) {
            switch(selectedElementType) {
                case "SUPPLIER":
                    element = {
                        "id": selectedElement.id,
                        "name": document.getElementById("name").value ? document.getElementById("name").value : selectedElement.name,
                        "active": document.getElementById("active").value ? document.getElementById("active").value : selectedElement.active
                        // "contact_person": document.getElementById("contactperson").value ? document.getElementById("contactperson").value : selectedElement.contact_person
                    }
                    url = "./api/UpdateSupplier";
                    break;
                case "SUBCUSTOMER":
                    element = {
                        "id": selectedElement.id,
                        "name": document.getElementById("name").value ? document.getElementById("name").value : selectedElement.name,
                        "parent_customer": document.getElementById("parent_customer").value ? document.getElementById("parent_customer").value : selectedElement.parent_customer,
                        "contact_person": document.getElementById("contactperson").value ? document.getElementById("contactperson").value : selectedElement.contact_person,
                        "address": document.getElementById("address").value ? document.getElementById("address").value : selectedElement.address
                    }
                    url = "./api/UpdateSubCustomer";
                    break;
                case "PERSON":
                    element = {
                        "id": selectedElement.id,
                        "name": document.getElementById("name").value ? document.getElementById("name").value : selectedElement.name,
                        "email": document.getElementById("email").value ? document.getElementById("email").value : selectedElement.email,
                        "telephone": document.getElementById("telephone").value ? document.getElementById("telephone").value : selectedElement.telephone,
                    }
                    url = "./api/UpdateContactPerson";
                    break;
    
            }
        } else {
            switch(selectedElementType) {
                case "SUPPLIER":
                    element = {
                        "name": document.getElementById("name").value,
                        "active": 1,
                        // "orgnr": document.getElementById("orgnr").value,
                        // "contact_person": document.getElementById("contactperson").value
                    }
                    if(element.name == "") valid = false;
                    url = "./api/CreateSupplier";
                    break;
                case "SUBCUSTOMER":
                    element = {
                        "name": document.getElementById("name").value,
                        "parent_customer": document.getElementById("parent_customer").value,
                        "contact_person": document.getElementById("contactperson").value,
                        "address": document.getElementById("address").value
                    }
                    if(element.name == "" || element.parent_customer == "" || element.contact_person == "" || element.address == "") valid = false;
                    url = "./api/CreateSubCustomer";
                    break;
                case "PERSON":
                    element = {
                        "name": document.getElementById("name").value,
                        "email": document.getElementById("email").value,
                        "telephone": document.getElementById("telephone").value
                    }
                    if(element.name == "" || element.email == "" || element.telephone == "") valid = false;
                    url = "./api/CreateContactPerson";
                    break;
            }
        }
        if(valid) {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(element),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then((response) => {
                if(!response.ok) {
                    SetToastIsError(true);
                } else {
                    SetToastIsError(false);
                }
                return response.text();   
            })
            .then((data) => {
                SetRefresh(Math.random()*500000);
                SetToastMessage(data);
                setShowToast(true);
                handleClose();
            })
            console.log("Sent update to backend")
            
        } else {
            console.log("not valid");
            SetModalErrorMsg("One or more fields are not valid!");
        }
    }

    const handleCreate = (type) => {
        SetModalErrorMsg(null);
        SetSelectedElement(null);
        console.log(type)
        SetIsCreateMode(true);
        setShow(true);
        SetSelectedElementType(type);
    }

    return(
        
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <Col>
                        <h2 className="my-0">Suppliers</h2>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <button className="btn btn-success my-2 mx-2" onClick={() => handleCreate("SUPPLIER")}>Create supplier</button>
                    </Col>
                </Row>
                    <Row className="mt-4">
                    <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="supplier" selected={selectedSuppliers} complete_object={suppliers} onChange={(return_data) => setSelectedSuppliers(return_data)}></FilterMultipleSelect>    
                        {/* <Form>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    onChange={setFilterActive(!filterActive)}
                                    label="Active"
                                    name="group1"
                                    type="switch"
                                    id={`inline-${type}-1`}
                                    checked
                                />
                                <Form.Check
                                    inline
                                    label="Unactive"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                />
                                </div>
                            ))}
                    </Form> */}
                    </Card.Header>
                    <Card.Body className="p-0 m-0">
                    {suppliers &&
                        <Table striped bordered hover className="m-0">
                            <thead>
                                <tr>
                                    <th className="text-center fw-bold">ID</th>
                                    <th className="fw-bold">Name</th>
                                    <th className="text-center fw-bold">Status</th>
                                </tr>
                            </thead>
                            <tbody style={{"cursor": "pointer"}}>
                                {suppliers.filter(function (s) {
                                        if(selectedSuppliers.length == 0){
                                            return true;
                                        }
                                        else {
                                            return selectedSuppliers.includes(s)
                                        }
                                    }).map(i => {
                                    return(
                                        <tr key={i.id} onClick={() => handleClick(i, "SUPPLIER")}>
                                            <td className="text-center">{i.id}</td>
                                            <td>{i.name}</td>
                                            <td className="text-center">{i.active == 1 && <Badge pill bg="success">Active</Badge>}{i.active == 0 && <Badge pill bg="secondary">Inactive</Badge>}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    </Card.Body>
                </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {selectedElement && selectedElementType == "SUPPLIER" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" placeholder={selectedElement.name} />
                        <label for="active">Active</label>
                        <input id="active" className="form-control" type="text" placeholder={selectedElement.active} onChange={(value) => validateNumber("int", "active", value.target.value)}/>
                        {error.includes("active") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                    </>
                    }
                    {selectedElement && selectedElementType == "SUBCUSTOMER" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" placeholder={selectedElement.name} />
                        
                        <label for="parent_customer">Parent Customer</label>
                        

                        <label for="contactperson">Contact Person</label>
                        <select name="contactperson" className="form-control" id="contactperson">
                            {contactPersons.map(person => {
                                if(selectedElement.contact_person == person.id) {
                                    return <option value={person.id} selected="selected">{person.name}</option>
                                }
                                return <option value={person.id}>{person.name}</option>
                            })}
                        </select>
                        
                        <label for="address">Address</label>
                        <input id="address" className="form-control" type="text" placeholder={selectedElement.address} />
                    </>
                    }
                    {selectedElement && selectedElementType == "PERSON" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" placeholder={selectedElement.name} />
                        
                        <label for="email">Email</label>
                        <input id="email" className="form-control" type="text" placeholder={selectedElement.email} />

                        <label for="telephone">Telephone</label>
                        <input id="telephone" className="form-control" type="text" placeholder={selectedElement.telephone} />
                        
                    </>
                    }
                    {isCreateMode && selectedElementType == "SUPPLIER" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" />
                        {/* <label for="orgnr">Org #</label> */}
                        {/* <input id="orgnr" className="form-control" type="text" /> */}
                        {/* <label for="contactperson">Contact Person</label> */}
                        {/* <select name="contactperson" className="form-control" id="contactperson"> */}
                            {/* {contactPersons.map(person => { */}
                                {/* return <option value={person.id} key={person.id}>{person.name}</option> */}
                            {/* })} */}
                        {/* </select> */}
                    </>
                    }
                    {isCreateMode && selectedElementType == "SUBCUSTOMER" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" />
                        
                        <label for="parent_customer">Parent Customer</label>
                       

                        <label for="contactperson">Contact Person</label>
                        <select name="contactperson" className="form-control" id="contactperson">
                            {contactPersons.map(person => {
                                return <option value={person.id} key={person.id}>{person.name}</option>
                            })}
                        </select>
                        
                        <label for="address">Address</label>
                        <input id="address" className="form-control" type="text"/>
                    </>
                    }
                    {isCreateMode && selectedElementType == "PERSON" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" />
                        
                        <label for="email">Email</label>
                        <input id="email" className="form-control" type="text"  />

                        <label for="telephone">Telephone</label>
                        <input id="telephone" className="form-control" type="text" />
                        
                    </>
                    }
                    {modalErrorMsg &&
                    <p className="text-danger">{modalErrorMsg}</p>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        {(error.length >= 1) &&
                            <Button variant="primary" disabled>Save</Button>
                        }
                        {(error.length == 0) &&
                            <Button variant="primary" onClick={handleSend}>Save</Button>
                        }
                        
                    </Modal.Footer>
            </Modal>
        
        </Row>
    </Container>   
            </AuthenticatedTemplate>
       </div>
    )
}