import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { silentRequest } from "./authConfig";

export function useFetchUserGroups() {
  const [userGroups, setUserGroups] = useState([]);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      fetchGroups();
    }
  }, [activeAccount]);

  async function fetchGroups() {
    try {
      const accessToken = await instance.acquireTokenSilent({
        ...silentRequest,
        account: activeAccount,
      });
      const response = await fetch("https://graph.microsoft.com/v1.0/me/memberOf", {
        headers: {
          Authorization: "Bearer " + accessToken.accessToken,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const filteredGroups = data.value.filter((group) =>
          group.displayName.startsWith("APP_PORTAL_")
        );
        setUserGroups(filteredGroups);
      } else {
        throw new Error("Error fetching groups");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return userGroups;
}