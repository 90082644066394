import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge, ListGroup} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import NavigationBar from "../../modules/NavigationBar";

import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import FilterMultipleWithLabels from "../../components/FilterMultipleWithLabels";

import { ReactSpreadsheetImport } from "react-spreadsheet-import";






export default function Products() {
    const [error, setError] = useState([]);

    const [filterActive, setFilterActive] = useState(true)
    function handleFilterActive(){
        setFilterActive(!filterActive);
    }

    const filterByFields = ['backstube_name', 'article_nr', 'gtin'];
    const filterByLabels = ['Name', 'Article nr.', 'gtin'];

    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [products, SetProducts] = useState()
    const [suppliers, SetSuppliers] = useState([]);
    const [pickupLocations, SetPickupLocations] = useState();
    const [selectedElement, SetSelectedElement] = useState();
    const [selectedElementType, SetSelectedElementType] = useState();
    const [isCreateMode, SetIsCreateMode] = useState(false);
    const [createElement, SetCreateElement] = useState();
    const [newElement, SetNewElement] = useState();
    const [refresh, SetRefresh] = useState();
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();


    // Import products
    const [importSuppliers, setImportSuppliers] = useState();
    const [importPickupLocations, setImportPickupLocations] = useState();



    const dfd = require("danfojs")
    const [importShow, setImportShow] = useState(false);
    const handleImportClose = () => setImportShow(false);
    const handleImportShow = () => setImportShow(true);

      // Determines if modal is visible.
    const [isOpen, setIsOpen] = useState(false);
    // Called when flow is closed without reaching submit.
    // onClose: () => void
    const onClose = () => console.log("mjau");
    // Called after user completes the flow. Provides data array, where data keys matches your field keys. 
    const onSubmit = (data, file) => console.log("mjau2");
    const fields = [
        {
            label: "Supplier",
            key: "supplier",
            fieldType: {
              type: "select",
              options: importSuppliers
            },
            example: "Ditsch",
            validations: [
              {
                rule: "required",
                errorMessage: "Supplier is required",
                level: "error",
              },
            ],
          },
          {
            label: "Pickup location",
            key: "pickup_location",
            fieldType: {
              type: "select",
              options: importPickupLocations
            },
            example: "Folschviller",
            validations: [
              {
                rule: "required",
                errorMessage: "Pickup location is required",
                level: "error",
              },
            ],
          },
          {
            // Visible in table header and when matching columns.
            label: "Name",
            // This is the key used for this field when we call onSubmit.
            key: "name",
            // Allows for better automatic column matching. Optional.
            alternateMatches: ["first name", "first"],
            // Used when editing and validating information.
            fieldType: {
              // There are 3 types - "input" / "checkbox" / "select".
              type: "input",
            },
            // Used in the first step to provide an example of what data is expected in this field. Optional.
            example: "Ostebørek",
            // Can have multiple validations that are visible in Validation Step table.
            validations: [
              {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "Name is required",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "error",
              },
            ],
          },
          {
            label: "Nature of cargo",
            key: "natureOfCargo",
            fieldType: {
              type: "select",
              options: [
                { label: "Frozen", value: "Frozen" },
                { label: "Dry", value: "Dry" },
              ]
            },
            example: "Frozen",
            validations: [
              {
                rule: "required",
                errorMessage: "Nature of cargo is required",
                level: "error",
              },
            ],
          },
      ]
    

    const [inputKey, setInputKey] = useState(Date.now()); // Use a unique key to force reset
    const [fileData, setFileData] = useState(null); // State to hold the validated data

    const [desiredKeys, setDesiredKeys] = useState({
        
            "supplier": {"name": "Supplier", "db_name": "supplier", "horizon": 1},
            "pickup_location": {"name": "Pickup Location", "db_name": "pickup_location", "horizon": 1},
            "name": {"name": "Name", "db_name": "name", "horizon": 1},
            "natureOfCargo": {"name": "Nature Of Cargo", "db_name": "natureOfCargo", "horizon": 1},
            "GTIN": {"name": "GTIN", "db_name": "GTIN", "horizon": 1},
            "article_nr": {"name": "Article Nr", "db_name": "article_nr", "horizon": 1},
            "transport_cost_per_pallet": {"name": "Transport Cost Per Pallet", "db_name": "transport_cost_per_pallet", "horizon": 2},
            "cartons_per_pallet_inbound": {"name": "Cartons Per Pallet Inbound", "db_name": "cartons_per_pallet_inbound", "horizon": 2},
            "RÅK_per_kilo": {"name": "RÅK Per Kilo", "db_name": "RÅK_per_kilo", "horizon": 2},
            "RAK_expiry": {"name": "RAK Expiry", "db_name": "RAK_expiry", "horizon": 2},
            "gross_weight": {"name": "Gross Weight", "db_name": "gross_weight", "horizon": 2},
            "net_weight": {"name": "Net Weight", "db_name": "net_weight", "horizon": 2},
            "pieces_per_quantity": {"name": "Units per carton", "db_name": "pieces_per_quantity", "horizon": 2},
            "box_factor": {"name": "Cartons per pallet, outbound", "db_name": "box_factor", "horizon": 3},
            "backstube_name": {"name": "Customer-facing name", "db_name": "backstube_name", "horizon": 3},
            "vat_code": {"name": "Vat Code", "db_name": "vat_code", "horizon": 3},
            "category": {"name": "Category", "db_name": "category", "horizon": 3},
            "kilo_emissions_per_kilo_product": {"name": "Kilo Emissions Per Kilo Product", "db_name": "kilo_emissions_per_kilo_product", "horizon": 3},
            "ingredients_original": {"name": "Ingredients Original", "db_name": "ingredients_original", "horizon": "other"},
            "ingredients_en": {"name": "Ingredients En", "db_name": "ingredients_en", "horizon": "other"},
            "ingredients_no": {"name": "Ingredients No", "db_name": "ingredients_no", "horizon": "other"},
            "dimension_x": {"name": "Dimension X", "db_name": "dimension_x", "horizon": "other"},
            "dimension_y": {"name": "Dimension Y", "db_name": "dimension_y", "horizon": "other"},
            "dimension_z": {"name": "Dimension Z", "db_name": "dimension_z", "horizon1": "other"},
            "backstube_order": {"name": "Backstube Order", "db_name": "backstube_order", "horizon": "other"},
            "allergen_wheat": {"name": "Wheat", "db_name": "1"},
            "allergen_rye": {"name": "Rye", "db_name": "2"},
            "allergen_soy": {"name": "Soy", "db_name": "3"},
            "allergen_barley": {"name": "Barley", "db_name": "4"},
            "allergen_lupin": {"name": "Lupin", "db_name": "5"},
            "allergen_pistacchio": {"name": "Pistacchio", "db_name": "6"},
            "allergen_cashew": {"name": "Cashew", "db_name": "7"},
            "allergen_almond": {"name": "Almond", "db_name": "8"},
            "allergen_milk": {"name": "Milk", "db_name": "10"},
            "allergen_egg": {"name": "Egg", "db_name": "11"},
            "allergen_oat": {"name": "Oat", "db_name": "12"},
            "allergen_spelt": {"name": "Spelt", "db_name": "13"},
            "allergen_crustaceans": {"name": "Crustaceans", "db_name": "14"},
            "allergen_fish": {"name": "Fish", "db_name": "15"},
            "allergen_peanut": {"name": "Peanut", "db_name": "16"},
            "allergen_hazelnut": {"name": "Hazelnut", "db_name": "17"},
            "allergen_walnut": {"name": "Walnut", "db_name": "18"},
            "allergen_brazil_nut": {"name": "Brazil Nut", "db_name": "19"},
            "allergen_macademia_nut": {"name": "Macademia Nut", "db_name": "20"},
            "allergen_cellery": {"name": "Cellery", "db_name": "21"},
            "allergen_mustard": {"name": "Mustard", "db_name": "22"},
            "allergen_sesame Seed": {"name": "Sesame Seed", "db_name": "23"},
            "allergen_sulfites": {"name": "Sulfites", "db_name": "24"},
            "allergen_molluscs": {"name": "Molluscs", "db_name": "25"},
            "allergen_pecan_nut": {"name": "Pecan Nut", "db_name": "26"}
        });

    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the uploaded file
        if (!file) {
        alert("No file selected!");
        return;
        }
        

        // Check if the file is an Excel file
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || 
            file.type === "application/vnd.ms-excel") {
            dfd.readExcel(file).then(df => {
                // console.log("df")
                // console.log(df)
                // Here you can validate the data frame (df) as per your requirements

                // Convert the DataFrame to an array of objects
                let mappedData = dfd.toJSON(df);
                // console.log("json")
                // console.log(mappedData)

                // Transform the array of objects to have the desired structure
                mappedData = mappedData.map(obj => {
                    // console.log("obj")
                    // console.log(obj)
                    const newObj = {"allergens": []};
                    for (const key in desiredKeys) {
                        const db_name = desiredKeys[key].db_name;
                        const name = desiredKeys[key].name;
                        if (key.slice(0,8) == "allergen"){
                            if (obj.hasOwnProperty(key)) {
                                newObj['allergens'].push({"db_name": db_name, "name": name});
                            }
                        } else {
                            newObj[key] = obj.hasOwnProperty(key) ? {"value": obj[key], "name": name, "horizon": desiredKeys[key]["horizon"]} : {"value": null, "name": name, "horizon": desiredKeys[key]["horizon"]};
                        }
                    }
                    // console.log("newObj")
                    // console.log(newObj)
                    return newObj;
                });

                // If validation is successful, update the state
                setFileData(mappedData); // Assuming setFileData accepts an array of objects
                console.log("mappedData");
                console.log(mappedData);
            });
        } else {
            alert("Please upload an Excel file.");
            setInputKey(Date.now()); // Reset the file input
        }
    };


    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    useEffect(() => {
        if(!token) return;
        fetch("./api/GetAllProductsDenormalized",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            } else {
                console.log(response.status)
            }
        })
        .then((data) => {
            SetProducts(data);
            console.log(data)
        });

        fetch("./api/GetAllSuppliers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetSuppliers(data);
            const mappedData = data.map(item => ({
                label: item.id + " - " + item.name,
                value: item.id,
            }));
            setImportSuppliers(mappedData);
        });
        fetch("./api/GetAllPickupLocations",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetPickupLocations(data);
            const mappedData = data.map(item => ({
                label: item.id + " - " + item.name,
                value: item.id,
            }));
            setImportPickupLocations(mappedData);
        });

    }, [token, refresh]);

    function validateNumber(type, name, inpValue){ // for front end input validation
        let validateOK = false;
        if (type === 'int'){
            if (!isNaN(inpValue)){
                if ((Math.round(inpValue) - inpValue) == 0){
                    validateOK = true;
                }
            }
        }
        else{
            if (!isNaN(inpValue)){
                validateOK = true;
            }
        }
                

        if (validateOK){
            if (error.includes(name)){                
                setError(error.filter(errorName => errorName !== name));
            }
        }
        else{
            if (!error.includes(name)){
                setError(prevError => [...prevError, name]);
            }
            else{console.log("I LISTEN: " + name);}
        }
    }
    const errorMessageStyling = {
        color: "red",
        margin: 0,
        padding: 0
    }
    const handleClick = (element, type) => {
        SetModalErrorMsg(null);
        SetIsCreateMode(false);
        SetSelectedElementType(type);
        SetSelectedElement(element);
        setShow(true);
    }

    const handleSend = () => {
        let element;
        let url;
        let valid = true;
        if(!isCreateMode) {
            switch(selectedElementType) {
                case "PRODUCT":
                    element = {
                        "id": selectedElement.id,
                        "supplier": document.getElementById("supplier").value ? document.getElementById("supplier").value : selectedElement.supplier,
                        "pickup_location": document.getElementById("pickup_location").value ? document.getElementById("pickup_location").value : selectedElement.pickup_location,
                        "name": document.getElementById("name").value ? document.getElementById("name").value : selectedElement.name,
                        "ingredients_original": document.getElementById("ingredients_original").value ? document.getElementById("ingredients_original").value : selectedElement.ingredients_original,
                        "ingredients_en": document.getElementById("ingredients_en").value ? document.getElementById("ingredients_en").value : selectedElement.ingredients_en,
                        "ingredients_no": document.getElementById("ingredients_no").value ? document.getElementById("ingredients_no").value : selectedElement.ingredients_no,
                        "kilo_emissions_per_kilo_product": document.getElementById("kilo_emissions_per_kilo_product").value ? document.getElementById("kilo_emissions_per_kilo_product").value : selectedElement.kilo_emissions_per_kilo_product,
                        "box_factor": document.getElementById("box_factor").value ? document.getElementById("box_factor").value : selectedElement.box_factor,
                        "dimension_x": document.getElementById("dimension_x").value ? document.getElementById("dimension_x").value : selectedElement.dimension_x,
                        "dimension_y": document.getElementById("dimension_y").value ? document.getElementById("dimension_y").value : selectedElement.dimension_y,
                        "dimension_z": document.getElementById("dimension_z").value ? document.getElementById("dimension_z").value : selectedElement.dimension_z,
                        "net_weight": document.getElementById("net_weight").value ? document.getElementById("net_weight").value : selectedElement.net_weight,
                        "gross_weight": document.getElementById("gross_weight").value ? document.getElementById("gross_weight").value : selectedElement.gross_weight,
                        "natureOfCargo": document.getElementById("natureOfCargo").value ? document.getElementById("natureOfCargo").value : selectedElement.natureOfCargo,
                        "GTIN": document.getElementById("gtin").value ? document.getElementById("gtin").value : selectedElement.gtin,
                        "backstube_order": document.getElementById("backstube_order").value ? document.getElementById("backstube_order").value : selectedElement.backstube_order,
                        "backstube_name": document.getElementById("backstube_name").value ? document.getElementById("backstube_name").value : selectedElement.backstube_name,
                        "pieces_per_quantity": document.getElementById("pieces_per_quantity").value ? document.getElementById("pieces_per_quantity").value : selectedElement.pieces_per_quantity,
                        "article_nr": document.getElementById("article_nr").value ? document.getElementById("article_nr").value : selectedElement.article_nr,
                        "active": document.getElementById("active").value ? document.getElementById("active").value : selectedElement.active,
                        "vat_code": document.getElementById("vat_code").value ? document.getElementById("vat_code").value : selectedElement.vat_code,
                        "råK_per_kilo": document.getElementById("RÅK_per_kilo").value ? document.getElementById("RÅK_per_kilo").value : selectedElement.råK_per_kilo,
                        "transport_cost_per_pallet": document.getElementById("transport_cost_per_pallet").value ? document.getElementById("transport_cost_per_pallet").value : selectedElement.transport_cost_per_pallet,
                        "cartons_per_pallet_inbound": document.getElementById("cartons_per_pallet_inbound").value ? document.getElementById("cartons_per_pallet_inbound").value : selectedElement.cartons_per_pallet_inbound,
                        "RAK_expiry": document.getElementById("RAK_expiry").value ? document.getElementById("RAK_expiry").value : selectedElement.raK_expiry,
                        "category": document.getElementById("category").value ? document.getElementById("category").value : selectedElement.category,
                        "has_pant": document.getElementById("has_pant").value ? document.getElementById("has_pant").value : selectedElement.has_pant,
                        "pant_per_carton": document.getElementById("pant_per_carton").value ? document.getElementById("pant_per_carton").value : selectedElement.pant_per_carton,

                    }
                    url = "./api/UpdateProduct";
                    break;
    
            }
        } else {
            switch(selectedElementType) {
                case "PRODUCT":
                    element = {
                        "name": document.getElementById("name").value,
                        "active": 1,
                        "supplier": document.getElementById("supplier").value,
                        "pickup_location": document.getElementById("pickup_location").value
                        
                        // "orgnr": document.getElementById("orgnr").value,
                        // "contact_person": document.getElementById("contactperson").value
                    }
                    if(element.name == "") valid = false;
                    url = "./api/CreateProduct";
                    break;
            }
        }
        if(valid) {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(element),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include'
            })
            .then((response) => {
                if(!response.ok) {
                    SetToastIsError(true);
                } else {
                    SetToastIsError(false);
                }
                return response.text();   
            })
            .then((data) => {
                SetRefresh(Math.random()*500000);
                SetToastMessage(data);
                setShowToast(true);
                handleClose();
            })
            console.log("Sent update to backend")
            
        } else {
            console.log("not valid");
            SetModalErrorMsg("One or more fields are not valid!");
        }
    }

    const handleCreate = (type) => {
        SetModalErrorMsg(null);
        SetSelectedElement(null);
        console.log(type)
        SetIsCreateMode(true);
        setShow(true);
        SetSelectedElementType(type);
    }

    return(
        <>

        <div>
            <AuthenticatedTemplate>
                <Container className="mt-5">
                    <ReactSpreadsheetImport isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />

                    <Row>
                        <Col className="d-flex align-items-center">
                            <h2 className="my-0">Products</h2>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {/* <Button variant="secondary" className="my-2 mx-2" onClick={() => handleImportShow()}>Import product(s)</Button> */}
                            <Button variant="secondary" className="my-2 mx-2" onClick={() => handleCreate("PRODUCT")}>Create product</Button>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {!products &&
                            <Col className="d-flex justify-content-center align-items-center">
                                <ThreeDots color="#0a4d6b"></ThreeDots>
                            </Col>
                        }
                        {products &&
                            <Card className="mb-3 p-0 col-12">
                                
                                <Card.Header>
                                    <FilterMultipleSelect name_of_filtered="supplier" selected={selectedSuppliers} complete_object={suppliers} onChange={(return_data) => setSelectedSuppliers(return_data)}></FilterMultipleSelect>
                                    <div className="mt-2"></div>
                                    <FilterMultipleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)} />
                                    
                                    <Form.Check
                                        className="mt-2"
                                        type="checkbox"
                                        id={`default-active`}
                                        label={`Only active products`}
                                        checked={filterActive}
                                        onChange={handleFilterActive}
                                    />
                                </Card.Header>
                                <Card.Body className="p-0 m-0">
                                
                                    <Table striped bordered hover className="m-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center fw-bold">Art. nr.</th>
                                                <th className="fw-bold">Name</th>
                                                <th className="text-center fw-bold">H1 - Inbound</th>
                                                <th className="text-center fw-bold">H2 - Inventory</th>
                                                <th className="text-center fw-bold">H3 - Distribution</th>
                                                <th className="text-center fw-bold">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{"cursor": "pointer"}}>
                                            {products.filter(function (s) {
                                                    if(selectedProducts.length == 0){
                                                        return true;
                                                    }
                                                    else {
                                                        return selectedProducts.includes(s)
                                                    }
                                                }).filter(function (p) {
                                                    if(!filterActive){
                                                        return true;
                                                    } else {
                                                        return p.active == 1
                                                    }
                                                }).filter(function (q) {
                                                    if(selectedSuppliers.length == 0){
                                                        return true;
                                                    }
                                                    else {
                                                        for (var ss in selectedSuppliers) {
                                                            if (selectedSuppliers[ss].id == q.supplier) {
                                                                return true
                                                            }
                                                        }
                                                        return false
                                                    }
                                                })
                                                .map(i => {
                                                return(
                                                    <tr key={i.id} onClick={() => handleClick(i, "PRODUCT")}>
                                                        <td className="text-center">{i.article_nr}</td>
                                                        <td style={{"padding": "4px 10px"}}>{i.backstube_name}</td>
                                                        <td className="text-center">{i.horizon_1_inbound == 1 && <Badge pill bg="success">Ok</Badge>}{i.horizon_1_inbound == 0 && <Badge pill bg="secondary">Data missing</Badge>}</td>
                                                        <td className="text-center">{i.horizon_2_inventory == 1 && <Badge pill bg="success">Ok</Badge>}{i.horizon_2_inventory == 0 && <Badge pill bg="secondary">Data missing</Badge>}</td>
                                                        <td className="text-center">{i.horizon_3_distribution == 1 && <Badge pill bg="success">Ok</Badge>}{i.horizon_3_distribution == 0 && <Badge pill bg="secondary">Data missing</Badge>}</td>
                                                        <td className="text-center">{i.active == 1 && <Badge pill bg="success">Active</Badge>}{i.active == 0 && <Badge pill bg="secondary">Inactive</Badge>}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                
                                </Card.Body>
                            </Card>
                        }

                    <ToastContainer className="p-3" position={"bottom-end"}>
                        <Toast show={showToast} onClose={handleToastClose}>
                            <Toast.Header>
                            <strong className="me-auto">Backstube</strong>
                            <small>now</small>
                            </Toast.Header>
                            <Toast.Body>
                                <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>

                    <Modal
                    size="xl"
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Element</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {selectedElement && selectedElementType == "PRODUCT" &&
                        <>
                            <Card>
                                <Card.Header>Horizon 1 - Inbound {selectedElement.horizon_1_inbound == 1 && <Badge className="mx-2" pill bg="success">Ok</Badge>}{selectedElement.horizon_1_inbound == 0 && <Badge className="mx-2" pill bg="secondary">Data missing</Badge>}</Card.Header>
                                <Card.Body>
                                    <label for="id">System ID</label>
                                    <input id="id" disabled className="form-control" type="text" placeholder={selectedElement.id} />
                                    <label for="supplier">Supplier</label>
                                    <input id="supplier" className="form-control" type="text" placeholder={selectedElement.supplier} onChange={(value) => validateNumber("int", "supplier", value.target.value)}/>
                                    {error.includes("supplier") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    <label for="pickup_location">Pick-up location</label>
                                    <input id="pickup_location" className="form-control" type="text" placeholder={selectedElement.pickup_location} onChange={(value) => validateNumber("int", "pickup_location", value.target.value)}/>
                                    {error.includes("pickup_location") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    <label for="name">Name</label>
                                    <input id="name" className="form-control" type="text" placeholder={selectedElement.name} />
                                    <label for="natureOfCargo">Nature of cargo</label>
                                    <input id="natureOfCargo" className="form-control" type="text" placeholder={selectedElement.natureOfCargo}/>

                                    <label for="gtin">GTIN</label>
                                    <input id="gtin" className="form-control" type="text" placeholder={selectedElement.gtin} onChange={(value) => validateNumber("int", "gtin", value.target.value)}/>
                                    <label for="article_nr">Art. nr</label>
                                    <input id="article_nr" className="form-control" type="text" placeholder={selectedElement.article_nr} onChange={(value) => validateNumber("int", "article_nr", value.target.value)}/>
                                    {error.includes("article_nr") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    {error.includes("gtin") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                </Card.Body>
                            </Card>
                            

                            <Card className="mt-5">
                                <Card.Header>Horizon 2 - Inventory {selectedElement.horizon_2_inventory == 1 && <Badge className="mx-2" pill bg="success">Ok</Badge>}{selectedElement.horizon_2_inventory == 0 && <Badge className="mx-2" pill bg="secondary">Data missing</Badge>}</Card.Header>
                                <Card.Body>
                                    <label for="RÅK_per_kilo">RÅK Per Kilo</label>
                                    <input id="RÅK_per_kilo" className="form-control" type="text" placeholder={selectedElement.råK_per_kilo} onChange={(value) => validateNumber("number", "RÅK_per_kilo", value.target.value)}/>
                                    {error.includes("RÅK_per_kilo") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="transport_cost_per_pallet">Transport Cost Per Pallet</label>
                                    <input id="transport_cost_per_pallet" className="form-control" type="text" placeholder={selectedElement.transport_cost_per_pallet} onChange={(value) => validateNumber("number", "transport_cost_per_pallet", value.target.value)}/>
                                    {error.includes("transport_cost_per_pallet") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="cartons_per_pallet_inbound">Cartons Per Pallet</label>
                                    <input id="cartons_per_pallet_inbound" className="form-control" type="text" placeholder={selectedElement.cartons_per_pallet_inbound} onChange={(value) => validateNumber("int", "cartons_per_pallet_inbound", value.target.value)}/>
                                    {error.includes("cartons_per_pallet_inbound") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    
                                    <label for="net_weight">Net weight / unit (grams)</label>
                                    <input id="net_weight" className="form-control" type="text" placeholder={selectedElement.net_weight} onChange={(value) => validateNumber("number", "dimension_z", value.target.value)}/>
                                    {error.includes("dimension_z") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="gross_weight">Gross weight / carton (KG)</label>
                                    <input id="gross_weight" className="form-control" type="text" placeholder={selectedElement.gross_weight} onChange={(value) => validateNumber("number", "gross_weight", value.target.value)}/>
                                    {error.includes("gross_weight") && <p style={errorMessageStyling}>Please enter a valid number</p>}
                                    <label for="pieces_per_quantity">Units / carton</label>
                                    <input id="pieces_per_quantity" className="form-control" type="text" placeholder={selectedElement.pieces_per_quantity} onChange={(value) => validateNumber("int", "pieces_per_quantity", value.target.value)}/>
                                    {error.includes("pieces_per_quantity") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                </Card.Body>
                            </Card>

                            <Card className="mt-5">
                                <Card.Header>Horizon 3 - Distribution {selectedElement.horizon_3_distribution == 1 && <Badge className="mx-2" pill bg="success">Ok</Badge>}{selectedElement.horizon_3_distribution == 0 && <Badge className="mx-2" pill bg="secondary">Data missing</Badge>}</Card.Header>
                                <Card.Body>
                                    <label for="box_factor">Cartons / pallet</label>
                                    <input id="box_factor" className="form-control" type="text" placeholder={selectedElement.box_factor} onChange={(value) => validateNumber("int", "box_factor", value.target.value)}/>
                                    {error.includes("box_factor") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    <label for="backstube_name">Customer-facing name</label>
                                    <input id="backstube_name" className="form-control" type="text" placeholder={selectedElement.backstube_name} />
                                    <label for="vat_code">VAT Code</label>
                                    <input id="vat_code" className="form-control" type="text" placeholder={selectedElement.vat_code} onChange={(value) => validateNumber("int", "vat_code", value.target.value)}/>
                                    {error.includes("vat_code") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    <label for="category">Category</label>
                                    <input id="category" className="form-control" type="text" placeholder={selectedElement.category} />
                                    <label for="kilo_emissions_per_kilo_product">KG CO2 / KG product</label>
                                    <input id="kilo_emissions_per_kilo_product" className="form-control" type="text" placeholder={selectedElement.kilo_emissions_per_kilo_product} onChange={(value) => validateNumber("number", "kilo_emissions_per_kilo_product", value.target.value)}/>
                                    {error.includes("kilo_emissions_per_kilo_product") && <p style={errorMessageStyling}>Please enter a valid number</p>}
                                    <label for="has_pant">Does the product have pant? (0 = no. 1 = yes)</label>
                                    <input id="has_pant" className="form-control" type="text" placeholder={selectedElement.has_pant} onChange={(value) => validateNumber("int", "has_pant", value.target.value)}/>
                                    {error.includes("has_pant") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="pant_per_carton">NOK pant / carton</label>
                                    <input id="pant_per_carton" className="form-control" type="text" placeholder={selectedElement.pant_per_carton} onChange={(value) => validateNumber("number", "pant_per_carton", value.target.value)}/>
                                    {error.includes("pant_per_carton") && <p style={errorMessageStyling}>Please enter a valid number</p>}

                                </Card.Body>
                            </Card>

                            <Card className="mt-5">
                                <Card.Header>Other data</Card.Header>
                                <Card.Body>
                                    <label for="RAK_expiry">RAK Expiry</label>
                                    <input id="RAK_expiry" className="form-control" type="date" defaultValue={selectedElement.raK_expiry}/>
                                    {error.includes("RAK_expiry") && <p style={errorMessageStyling}>Please enter a valid integer</p>}
                                    <label for="ingredients_original">Ingredients, original</label>
                                    <textarea id="ingredients_original" className="form-control" type="text" placeholder={selectedElement.ingredients_original} />
                                    <label for="ingredients_en">Ingredients, English</label>
                                    <textarea id="ingredients_en" className="form-control" type="text" placeholder={selectedElement.ingredients_en} />
                                    <label for="ingredients_no">Ingredients, Norwegian</label>
                                    <textarea id="ingredients_no" className="form-control" type="text" placeholder={selectedElement.ingredients_no} />
                                    

                                    

                                    <label for="dimension_x">Dimension X (cm)</label>
                                    <input id="dimension_x" className="form-control" type="text" placeholder={selectedElement.dimension_x} onChange={(value) => validateNumber("number", "dimension_x", value.target.value)}/>
                                    {error.includes("dimension_x") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="dinension_y">Dimension Y (cm)</label>
                                    <input id="dimension_y" className="form-control" type="text" placeholder={selectedElement.dimension_y} onChange={(value) => validateNumber("number", "dimension_y", value.target.value)}/>
                                    {error.includes("dimension_y") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="dimension_z">Dimension Z (cm)</label>
                                    <input id="dimension_z" className="form-control" type="text" placeholder={selectedElement.dimension_z} onChange={(value) => validateNumber("number", "dimension_z", value.target.value)}/>
                                    {error.includes("dimension_z") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="backstube_order">Order form sequence</label>
                                    <input id="backstube_order" className="form-control" type="text" placeholder={selectedElement.backstube_order} onChange={(value) => validateNumber("int", "backstube_order", value.target.value)}/>
                                    {error.includes("backstube_order") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                    <label for="active">Active</label>
                                    <input id="active" className="form-control" type="text" placeholder={selectedElement.active} onChange={(value) => validateNumber("int", "active", value.target.value)}/>
                                    {error.includes("active") && <p style={errorMessageStyling}>Please enter a valid integer</p>}

                                </Card.Body>
                            </Card>
                        </>
                        }
                        
                        {isCreateMode && selectedElementType == "PRODUCT" &&
                        <>
                            <label for="name">Name</label>
                            <input id="name" className="form-control" type="text" />
                            <label for="supplier">Supplier</label>

                            <select name="supplier" className="form-control" id="supplier">
                                {suppliers.map(supplier => {
                                    return <option value={supplier.id} key={supplier.id}>{supplier.name}</option>
                                })}
                            </select>
                            <label for="pickup_location">Pick-up location</label>

                            <select name="pickup_location" className="form-control" id="pickup_location">
                                {pickupLocations.map(location => {
                                    return <option value={location.id} key={location.id}>{location.name}</option>
                                })}
                            </select>
                            {/* <label for="orgnr">Org #</label> */}
                            {/* <input id="orgnr" className="form-control" type="text" /> */}
                            {/* <label for="contactperson">Contact Person</label> */}
                            {/* <select name="contactperson" className="form-control" id="contactperson"> */}
                                {/* {contactPersons.map(person => { */}
                                    {/* return <option value={person.id} key={person.id}>{person.name}</option> */}
                                {/* })} */}
                            {/* </select> */}
                        </>
                        }
                        
                        {modalErrorMsg &&
                        <p className="text-danger">{modalErrorMsg}</p>
                        }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Cancel
                            </Button>


                            {(error.length >= 1) &&
                                <Button variant="primary" disabled>Save</Button>
                            }
                            {(error.length == 0) &&
                                <Button variant="primary" onClick={handleSend}>Save</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                    <Modal
                    size="xl"
                    show={importShow}
                    onHide={handleImportClose}
                    backdrop="static"
                    keyboard={true}
                    centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Import product(s)</Modal.Title>
                            
                        </Modal.Header>
                        <Modal.Header className="justify-content-start">
                            <a className="" href="#">Fill in this Excel template.</a><span className="mx-1">The first X columns are required.</span>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="formFileExcel" className="mb-3">
                                <Form.Label>Select file</Form.Label>
                                <Form.Control key={inputKey} accept=".xlsx, .xls" type="file" onChange={handleFileUpload} />
                            </Form.Group>
                            {fileData &&
                            <>
                   


                            {fileData.map((item, index) => (
                                <Card key={index} className="mb-4">
                                  <Card.Body>
                                    <Card.Title>New product {index+1} - {item.article_nr.value} {item.name.value}</Card.Title>
                                    <ListGroup variant="flush">
                                        
                                      {Object.entries(item).map(([key, value], idx) => (
                                        <ListGroup.Item key={idx}>
                                           
                                          {typeof value === 'object' && value !== null
                                            ? Array.isArray(value)
                                              ? <><strong>Allergens: </strong> {value.map((a) => (a["name"] + ". "))} </> // Simplified handling for arrays
                                              : <>
                                                    <strong>
                                                        {item[key].name + ": "}
                                                    </strong>
                                                        {value.value}
                                                        {key == "supplier"
                                                            ? suppliers.find(x => x.id == value.value) == undefined
                                                                ? " - Invalid supplier number"
                                                                : " - " + suppliers.find(x => x.id == value.value).name
                                                            : ""
                                                        } 
                                                        {key == "pickup_location"
                                                            ? pickupLocations.find(x => x.id == value.value) == undefined
                                                                ? " - Invalid location number"
                                                                : " - " + pickupLocations.find(x => x.id == value.value).name
                                                            : ""
                                                        }
                                                </> // Placeholder for objects, could further iterate if simple
                                            : value.toString()}
                                        </ListGroup.Item>
                                      ))}
                                    </ListGroup>
                                  </Card.Body>
                                </Card>
                              ))}

                            </>
                            }
                            
                        </Modal.Body>
                    </Modal>
            
                    </Row>
                </Container>          
            </AuthenticatedTemplate>
    </div>
    </>
    )
}