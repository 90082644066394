import React from "react";
import { useState, useEffect } from "react";
import ListElement from "./ListElement";
import { Card, ListGroup, ListGroupItem, Table, Toast, ToastContainer, Modal, Button, Dropdown, DropdownButton, Container, Row, Col } from 'react-bootstrap';
import { RotatingSquare, ThreeDots } from "react-loader-spinner";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";


export default function Order(props) {

    // const auth = useAuth();
    
    const [products, setProducts] = useState([]);        
    const [orderSchema, setOrderSchema] = React.useState([]);
    const [minDateString, setMinDateString] = useState("");
    const [maxDateString, setMaxDateString] = useState("");
    const [chosenDate, setChosenDate] = React.useState("");
    const [dateOk, setDateOk] = React.useState(true);
    const [orderSchemaOK, setOrderSchemaOk] = React.useState(true);
    const [carrierOk, setCarrierOk] = React.useState(true);
    const [modal, setModal] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [getProductsErrorMsg, setGetProductsErrorMsg] = useState(null);
    const [productListLoading, setProductListLoading] = useState(false);
    const handleCloseModal = () => setModal(false);    
    const [showToast, setShowToast] = useState(false);
    //console.log(props.natureOfCargo);
    const [numberOfPallets, setNumberOfPallets] = React.useState([]);
    const [numberOfBoxes, setNumberOfBoxes] = React.useState(0);
    const [numberOfUnits, setNumberOfUnits] = React.useState(0);
    
    const [carriers, setCarriers] = React.useState([]);
    const [carrier, setCarrier] = React.useState("");        
    const [hasToken, SetHasToken] = useState(false);
    const [loading, setLoading] = useState(true);
    
    
    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])

    useEffect(() => {
        if(token != null) {
            fetch("./api/GetUserSubCustomers", {
                headers: new Headers({
                    'Authorization': `Bearer ${token}`, 
                })
            })
            .then(response => response.json())
            .then(data => {
                SetSubCustomers(data);
            });
        }
    }, [token]);
    

    const [currentSubCustomer, setCurrentSubCustomer] = useState(null);
    const [currentSubCustomerIdTest, setCurrentSubCustomerIdTest] = useState(1);
    const [subCustomers, SetSubCustomers] = useState();

    useEffect(() => {
        if(!token) return
        console.log(hasToken)
        fetch("./api/GetUserSubCustomers", {
            headers: {
                "Authorization": `Bearer ${token}` 
            }
        })
        .then(response => response.json())
        .then(data => {
            SetSubCustomers(data);
        });
    }, [token])

    useEffect(() => {
        if(!token) return
        console.log("Sub customers: ", subCustomers)
        if(subCustomers != null) {
            setCurrentSubCustomer(subCustomers[0])
        }
    }, [subCustomers, token])

    // hvis bestilling feiler, så vises en error modal
    const [errorModal, setErrorModal] = React.useState(false);

    const style = {
        color: "red"
    }        

    const handleShowModal = () => {        
        if (chosenDate === ""){
            setDateOk(false);
            return;
        }
        
        setDateOk(true);

        let products = orderSchema.filter(product => product.amount !== "");
        
        // sjekker om antall produkter er mindre enn 0, hvis det er tilfelle er det ingen produkter i ordreskjema
        if (products.length < 1){
            setOrderSchemaOk(false);
            return;
        }

        setOrderSchemaOk(true);

        if (carrier === ""){
            setCarrierOk(false);
            return;
        }
        
        setCarrierOk(true);
        setProducts(products);                       
        setModal(true);
    };

    const handleButtonClick = () => {    
        const chosenCarrier = carriers.find(currentCarrier => currentCarrier.name === carrier);
        
        let finalObj = {
            products,
            "address": currentSubCustomer.address,
            "date": chosenDate,
            "email": "sjef@backstubebutikk.no",
            "transport_id": chosenCarrier.id,
            "transport_by": chosenCarrier.name,
            "transport_code": chosenCarrier.code,
            "sub_customer": currentSubCustomer.id
        }
        console.log(finalObj)
        console.log("finalObj")

        setIsLoading(true)

        fetch('./api/createorder', {
            method: "POST",
            body: JSON.stringify(finalObj),
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            })
        })
        .then((response) => {
            if(response.ok) {
                handleCloseModal();
                setShowToast(true);
                setIsLoading(false)
                return;
            } else {
                console.error("Error in Order.jsx primary fetch.");
            }
        }).catch((error) => {
            handleCloseModal();
            setIsLoading(false)
            setErrorModal(true);
            setErrorMsg(error);
        })

       
    }

    const handleToastClose = () => {
        setShowToast(!showToast);
    }
    
    function handleUpdate(gtin, event)
    {
        const {value} = event.target;         
        if (isNaN(value)){return;}
        const validateValue = /^[0-9]*$/;
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        if (value.toString().charAt(0) === "0"){console.log("kan ikke starte med 0"); return;}
        // setNumberOfPallets(calculateNumberOfPallets());
        setOrderSchema(prevOrderSchema => prevOrderSchema.map(item => {
            if(item.procured_product.GTIN === gtin)
            {
                return{
                    ...item,
                    amount: value
                }
            }            
            return item;
        }))


        let calcNumberOfPallets = 0;
        orderSchema.forEach(element => {
            if(element.procured_product.GTIN === gtin) {
                calcNumberOfPallets += value * (1 / element.box_factor)
            } else {
                calcNumberOfPallets += element.amount * (1 / element.box_factor)
            }
        })
        setNumberOfPallets(Math.round(calcNumberOfPallets * 100) / 100)

        
        let calcNumberOfBoxes = 0;
        orderSchema.forEach(element => {
            if(element.procured_product.GTIN === gtin) {
                calcNumberOfBoxes += value * 1
            } else {
                calcNumberOfBoxes += element.amount * 1
            }
        })
        setNumberOfBoxes(calcNumberOfBoxes)

        let calcNumberOfUnits = 0;
        orderSchema.forEach(element => {
            if(element.procured_product.GTIN === gtin) {
                calcNumberOfUnits += value * element.pieces_per_quantity
            } else {
                calcNumberOfUnits += element.amount * element.pieces_per_quantity
            }
        })
        setNumberOfUnits(calcNumberOfUnits)
    }
    
    function handleDato(event){
        const {value} = event.target;
        setChosenDate(value);
    }
    
    React.useEffect(() => 
    {
        if(!token || !currentSubCustomer) return
        setGetProductsErrorMsg(null)
        setProductListLoading(true);
        setOrderSchema([]);
        setNumberOfPallets(0);

        setGetProductsErrorMsg(null);
            fetch('./api/GetSubCustomerProducts', {
                method: "POST",
                body: JSON.stringify(currentSubCustomer.id),
                headers: new Headers({
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }),
            })
            .then((response) => {
                console.log(response.status)
                console.log(response.ok)
                if(response.ok) {
                    return response.json();
                }
                console.log("Error fetching products.");
            })
            .then((data) => {
                let products = data.sort((a, b) => a.category.localeCompare(b.category)).filter(item => item.natureOfCargo === props.natureOfCargo).map(filteredItem => {
                    return{
                        "procured_product": {
                            "name": filteredItem.pname,
                            "backstube_name": filteredItem.backstube_name,
                            "GTIN": filteredItem.gtin,
                            "Id": filteredItem.product_id,
                            "article_nr": filteredItem.article_nr,
                        },
                        "amount": "",
                        "pallets": 0,
                        "net_price_per_quantity": filteredItem.net_price_per_quantity,
                        "note_1": filteredItem.note_1,
                        "note_2": filteredItem.note_2,
                        "pieces_per_quantity": filteredItem.pieces_per_quantity,
                        "box_factor": filteredItem.box_factor
                    }
                });
                setOrderSchema(products)
                setProductListLoading(false)
            })
            .catch((error) => {
                setGetProductsErrorMsg(error);
                setProductListLoading(false)
            })

            // fetch kall for å hente carriers
            fetch('./api/getAllCarriers',{
                headers: new Headers({
                    'Authorization': `Bearer ${token}`, 
                })
            })
            .then((response) => {
                if(response.ok){
                    return response.json();
                }
                console.log("Error fetching carriers");
            })
            .then((data) => {
                setCarriers(data);
            })
    },[props.natureOfCargo, currentSubCustomer, token])    

    let productElements = orderSchema.map(product => {
        return (
            <ListElement product={product} amount={product.amount} handleUpdate={handleUpdate} key={product.procured_product.GTIN}/>
        )
    })

    const configureCalendar = useEffect(() => {
        let today = new Date();
        setMinDateString(today.toISOString().split('T')[0]);
        today.setDate(today.getDate() + 14);
        setMaxDateString(today.toISOString().split('T')[0]);
    }, []);
    

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Recommended number of boxes in local storage
        </Tooltip>
        );
    const renderTooltip3 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Daily demand in units. (For instance: 1 = 1 croissant)
        </Tooltip>
        );
    const renderTooltip4 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            This is the amount of boxes you want to order
        </Tooltip>
        );
    const renderTooltip5 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Number of units per box
        </Tooltip>
        );
           
    // return(
    //     <>Loading...</>
    // )
    return(
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <Col>
                        <h2>Order {props.natureOfCargo.toLowerCase()} goods</h2>
                    </Col>
                    
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                
                <Col>
                    <Card>
                        <Card.Header>Ordering for</Card.Header>
                        <Card.Body>
                            <Card.Title>{currentSubCustomer ? currentSubCustomer.name : "Loading..."}</Card.Title>
                            <Card.Text>{currentSubCustomer ? currentSubCustomer.address : "Loading..."}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                        {/* {subCustomers && (subCustomers.length > 1) && */}
                            {subCustomers && (subCustomers.length > 1) &&
                                <DropdownButton
                                drop="down-left"
                                variant="primary"
                                size="sm"
                                title="Change">
                                    {subCustomers.map(sc => {
                                        return <Dropdown.Item onClick={() => {setCurrentSubCustomer(sc)}}>{sc.name}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                            }
                            {subCustomers && (subCustomers.length === 1) &&
                                <DropdownButton
                                drop="down-left"
                                variant="primary"
                                size="sm"
                                disabled
                                title="Change">
                                    {/* {auth.user.sub_customers.map(sc => {
                                        return <Dropdown.Item onClick={() => {setCurrentSubCustomer(sc)}}>{sc.name}</Dropdown.Item>
                                    })} */}
                                </DropdownButton>
                            }
                        </Card.Footer>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>Order information</Card.Header>
                        <ListGroup variant="flush">
                            {props.natureOfCargo === "Frozen" &&
                                <ListGroupItem>
                                    Number of pallets: {numberOfPallets}
                                </ListGroupItem>
                            }
                            <ListGroupItem>
                                Number of boxes: {numberOfBoxes}
                            </ListGroupItem>
                            <ListGroupItem>
                                Number of units: {numberOfUnits}
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                    
                </Col>
                <Col>
                    <Card>
                        <Card.Header>Pick-up date and carrier</Card.Header>
                        {/* <Card.Body> */}
                            <ListGroup variant="flush">
                                <ListGroup.Item className="mt-2">
                                    <div className="mb-2">Pick-up date at FrigoScandia</div>
                                    <input onChange={handleDato} type="date" className="form-control" id="start" name="trip-start"
                                        value={chosenDate}                                        
                                        min={minDateString}                        
                                    />
                                    <div className="mt-2"></div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="my-2">Carrier</div>
                                    <Form.Select 
                                        size="md"
                                        value={carrier}
                                        onChange={(event) => setCarrier(event.target.value)}
                                        name="name"                    
                                        aria-label="Default select example">
                                            <option value="">Select carrier</option>
                                            {carriers.filter(c => c.active === 1).map(currentCarrier => <option key={currentCarrier.id} value={currentCarrier.name}>{currentCarrier.name.toLowerCase()}</option>)}
                                    </Form.Select>
                                    <div className="mt-2"></div>
                                </ListGroup.Item>
                            </ListGroup>
                        {/* </Card.Body> */}
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>Confirm</Card.Header>
                        <Card.Body>
                            Please ensure that the order details are correct before proceeding.
                            {!dateOk && <p className="inter-font" style={style}>Please enter a valid date.</p>}
                            {!orderSchemaOK && <p className="inter-font" style={style}>You did not select any products. Product list can not be empty.</p>}
                            {!carrierOk && <p className="inter-font" style={style}>Please select a carrier</p>}

                        </Card.Body>
                        <Card.Footer>
                            <Button size="sm" variant="success" onClick={handleShowModal}>Confirm order</Button>
                            
                        </Card.Footer>
                    </Card>
                </Col>
                </Row>        
            </Container>
            <Container className="mt-5">
                <Row>
                    <Col>
            
            
                <div>
                    <ul className="list-group my-1">
                        {getProductsErrorMsg &&
                        <div className="card">
                            <p className="text-center card-body text-secondary mb-0">An error occured fetching the product list. Please contact a site administrator if the error persists.</p>
                            <p className="text-center card-body text-danger mt-0 mb-0">{getProductsErrorMsg.toString()}</p>
                        </div>
                        }
                        <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#3e3e3e" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={productListLoading}
                            />
                        </div>
                        
                        <Table striped bordered>
                        <thead>
          <tr>
          
            <th className="px-2" style={{ width: "10%",  "text-align": "center"}}>Art. #</th>
            <th className="px-2" style={{ width: "30%" }}>Product</th>
            
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip2}
    >
            <th className="text-center" style={{ width: "10%" }}>Should have</th>
            </OverlayTrigger>
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip3}
    >
            <th className="text-center" style={{ width: "10%" }}>Units / day</th>
            </OverlayTrigger>
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip4}
    >
            <th className="text-center" style={{ width: "20%" }}>Number of boxes</th>
            </OverlayTrigger>
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip5}
    >
            <th className="text-center" style={{ width: "10%" }}>Units / box</th>
            </OverlayTrigger>
          </tr>
        </thead>
        <tbody>
        {productElements}

        </tbody>
                        
                        </Table>
                    </ul>
                </div>
                                                                                

                
                
                <Modal
                show={modal}
                onHide={handleCloseModal}
                keyboard={false}
                backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You're about to place an order. To proceed, press Confirm.</p>
                        <div className="d-flex justify-content-center align-items-center">
                            <RotatingSquare
                            height="100"
                            width="100"
                            color="#3e3e3e"
                            ariaLabel="rotating-square-loading"
                            strokeWidth="4"
                            wrapperStyle={{}}
                            wrapperClass="justify-content-center align-items-center"
                            visible={isLoading}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="flex justify-content-between">
                        <button className="btn btn-danger" onClick={handleCloseModal}>Cancel</button>
                        {isLoading &&
                        <button className="btn btn-success" disabled>Confirm</button>}
                        {!isLoading &&
                        <button className="btn btn-success" onClick={handleButtonClick}>Confirm</button>}
                    </Modal.Footer>
                </Modal>                

                <Modal
                show={errorModal}
                onHide={() => setErrorModal(false)}
                keyboard={false}
                backdrop="static"
                >    
                    <Modal.Header closeButton>
                        <Modal.Title>An error has occured</Modal.Title>                        
                    </Modal.Header>          
                    <Modal.Body>
                        <p style={style}>An error has occured. The order has not been placed. Please try again.</p>
                        <p><br />{errorMsg}</p>
                    </Modal.Body>                         
                    <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setErrorModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                
                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>Your order has been placed.</Toast.Body>
                    </Toast>
                </ToastContainer>

            </Col>
            </Row>
            </Container>
                        
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Confirm Order</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>You're about to place an order. To proceed, press Confirm.</p>                    
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" id="liveToastBtn" onClick={handleButtonClick} className="btn btn-success" data-bs-dismiss="modal">Confirm</button>
                </div>
                </div>
            </div>
            
        
        </div>
            
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <p>Ingen tilgang</p>
        </UnauthenticatedTemplate>
    </div>
    )
}