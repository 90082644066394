
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { useFetchUserGroups } from "../util/fetchUserGroups";
import { useState } from "react";

const groupAccessRules = {
    'APP_PORTAL_IS': ['/orderfrozen', '/orderdry', '/orderhistory'],
    'APP_PORTAL_ES': ['/orderfrozen', '/orderdry', '/orderhistory'],
    'APP_PORTAL_AM': ['/orderfrozen', '/orderdry', '/orderhistory', '/suppliers', '/customers', '/customerprices', '/subcustomeraccess', '/productstock', "notDefaultUser"],
    'APP_PORTAL_PM': ['/orderfrozen', '/orderdry', '/orderhistory', '/suppliers', '/customers', '/products', '/allergens', '/inventory', '/inventoryvalue', '/salesvolume', "notDefaultUser", "/inbound", "/productbatchstatus", "/pricehistory", "/pickuplocations", "/carriers"],
    'APP_PORTAL_ADMIN': ['/orderfrozen', '/orderdry', '/orderhistory', '/suppliers', '/customers', '/customerprices', '/subcustomeraccess', '/products', '/allergens', '/inventory', '/inventoryvalue', '/salesvolume', "notDefaultUser", "/inbound", "/productbatchstatus", "/pricehistory", "/pickuplocations", "/carriers"], 

}

const NavigationBar = () => {
    const { instance } = useMsal();
    const userGroups = useFetchUserGroups();
    const userRoutes = userGroups.flatMap(group => groupAccessRules[group.displayName] || [])
    const [expanded, setExpanded] = useState(false);

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        })
    }

    const handleLogoutRedirect = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        })
    }
    return(

        
        <Navbar variant="light" bg="light" expand="true" expanded={expanded}>
            <Container>
                <Navbar.Brand>
                    <NavLink style={{ color: 'inherit', textDecoration: 'none' }} to="/" onClick={() => setExpanded(false)}>
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    /> {' '}
                    Order Portal </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                            <NavLink to="/" className="mt-4 nav-link-custom" onClick={() => setExpanded(false)}>Dashboard</NavLink>
                            <h5 className="mt-3">Order</h5>
                            <NavLink className="nav-link-custom" to="/orderfrozen" onClick={() => setExpanded(false)}>Order frozen goods</NavLink>
                            <NavLink className="nav-link-custom" to="/orderdry" onClick={() => setExpanded(false)}>Order dry goods</NavLink>
                            {/* {[1,2,3,4,5].includes(auth?.user.role) && */}
                            <NavLink className="nav-link-custom" to="/orderhistory" onClick={() => setExpanded(false)}>Order history</NavLink>
                            
                            
                            {userRoutes.includes('/suppliers') && 
                            <h5 className="mt-4 category-separator">Database</h5>}
                            {userRoutes.includes('/customers') && 
                            <NavLink className="nav-item nav-link-custom" to="/customers"><p className="nav-link" onClick={() => setExpanded(false)}>Customers</p></NavLink>}
                            {userRoutes.includes('/customerprices') && 
                            <NavLink className="nav-item nav-link-custom" to="/customerprices"><p className="nav-link" onClick={() => setExpanded(false)}>Customer Prices</p></NavLink>}
                            {userRoutes.includes('/subcustomeraccess') && 
                            <NavLink className="nav-item nav-link-custom" to="/subcustomeraccess"><p className="nav-link" onClick={() => setExpanded(false)}>Sub Customer Access</p></NavLink>}
                            {userRoutes.includes('/products') && 
                            <NavLink className="nav-item nav-link-custom" to="/products"><p className="nav-link" onClick={() => setExpanded(false)}>Products</p></NavLink>}
                            {userRoutes.includes('/suppliers') && 
                            <NavLink className="nav-item nav-link-custom" to="/suppliers"><p className="nav-link" onClick={() => setExpanded(false)}>Suppliers</p></NavLink>}
                            {userRoutes.includes('/pickuplocations') && 
                            <NavLink className="nav-item nav-link-custom" to='/pickuplocations'><p className="nav-link" onClick={() => setExpanded(false)}>Pick-up Locations</p></NavLink>}
                            {userRoutes.includes('/carriers') && 
                            <NavLink className="nav-item nav-link-custom" to="/carriers"><p className="nav-link" onClick={() => setExpanded(false)}>Carriers</p></NavLink>}
                            {userRoutes.includes('/allergens') && 
                            <NavLink className="nav-item nav-link-custom" to="/allergens"><p className="nav-link" onClick={() => setExpanded(false)}>Allergens</p></NavLink>}
                            
                            
                            {userRoutes.includes('/inbound') && 
                            <h5 className="mt-4 category-separator">Insight</h5>}
                            {userRoutes.includes('/inbound') && 
                            <NavLink className="nav-item nav-link-custom" to="/inbound"><p className="nav-link" onClick={() => setExpanded(false)}>Inbound</p></NavLink>}
                            {userRoutes.includes('/productbatchstatus') && 
                            <NavLink className="nav-item nav-link-custom" to="/productbatchstatus"><p className="nav-link" onClick={() => setExpanded(false)}>Product Batch Status</p></NavLink>}
                            {userRoutes.includes('/inventory') && 
                            <NavLink className="nav-item nav-link-custom" to="/inventory"><p className="nav-link" onClick={() => setExpanded(false)}>Inventory</p></NavLink>}
                            {userRoutes.includes('/inventoryvalue') && 
                            <NavLink className="nav-item nav-link-custom" to="/inventoryvalue"><p className="nav-link" onClick={() => setExpanded(false)}>Inventory Value</p></NavLink>}
                            {userRoutes.includes('/salesvolume') && 
                            <NavLink className="nav-item nav-link-custom" to="/salesvolume"><p className="nav-link" onClick={() => setExpanded(false)}>Sales Volume</p></NavLink>}
                            {userRoutes.includes('/pricehistory') && 
                            <NavLink className="nav-item nav-link-custom" to="/pricehistory"><p className="nav-link" onClick={() => setExpanded(false)}>Price History</p></NavLink>}

                            <a className="mt-4 nav-link-custom nav-link text-danger" style={{cursor: 'pointer'}} onClick={handleLogoutRedirect}>Logout</a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavigationBar