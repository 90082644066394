import React, { useEffect, useState } from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import NavigationBarMsal from "../modules/NavigationBar";
import { useFetchUserGroups } from "../util/fetchUserGroups";

const ExampleMsal = () => {
  const { accounts } = useMsal(); // Get user
  const userGroups = useFetchUserGroups(); // Fetch user groups

  return (
    <>
      <NavigationBarMsal />
      <AuthenticatedTemplate>
        <h1>Welcome, {accounts[0]?.name}!</h1>
        <p>You have successfully logged in using MSAL and Azure AD.</p>

        <div>
          <h2>Groups</h2>
          <ul>
            {userGroups.map((group) => (
              <li>{group.displayName}</li>
            ))}
          </ul>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h1>Please sign in to view this content.</h1>
      </UnauthenticatedTemplate>
    </>
  );
};

export default ExampleMsal; 
