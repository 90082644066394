import { useState } from 'react';
import { useNavigate, useLocation, NavLink  } from 'react-router-dom';
import { MutatingDots } from "react-loader-spinner";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../../components/SignInButton";




export default function Login(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    
    return(
        <div className="container-fluid vh-100 d-flex justify-content-center align-items-center flex-column">
          <>
            <div className="col-12 col-sm-8 col-sm-6 col-md-4 col-lg-3 text-center">
                {isAuthenticated ? "" : <h3 className="inter-font">User Login</h3> }
                {isAuthenticated ? <NavLink className="nav-link-custom" to="/"><h3>You are already logged inn </h3></NavLink> :  <SignInButton />}
                {errorMsg &&
                    <p className="text-danger text-center">{errorMsg}</p>
                }
            </div>
            <MutatingDots 
            height="100"
            width="100"
            color="#3e3e3e"
            secondaryColor= '#3e3e3e'
            radius='12.5'
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loading}
            />
            </>
        </div>
    )
}