import { InfinitySpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { InteractionType } from '@azure/msal-browser';
import NavigationBar from "../../modules/NavigationBar";
import { Navbar, Nav, Row, Container, Button} from "react-bootstrap"
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";


export default function Dashboard(props) {

    

    return(
        <>
            <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect}>
            <Container className="mt-5">
                <Row>
                    <h2>Dashboard</h2>
                </Row>
            </Container>
            
            
               
            <Container className="mt-4">
                <Row>
                <NavLink className="nav-item nav-link-custom" to="/orderfrozen"><Button variant="secondary">Order frozen goods</Button></NavLink>
                <NavLink className="nav-item nav-link-custom mt-2" to="/orderdry"><Button variant="secondary">Order dry goods</Button></NavLink>
                
                </Row>
            </Container>

            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                <div className="position-fixed fixed-bottom text-center">
                    <p className="text-secondary">Application Version 23.07.28.1</p>
                </div>
            </div>
            </MsalAuthenticationTemplate>
        </>
    )
}