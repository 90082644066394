import { useEffect } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import moment from "moment/moment";
import { Badge, Row, Col, ListGroup, ListGroupItem } from "react-bootstrap";



export default function InventoryValueElement(props) {
    
    const dfd = require("danfojs")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function sumThree(a, b, c) {
        let d, e, f;
        if (a == NaN || a == null || typeof a != "number") {
            d = 0;
        } else {
            d = a;
        }
        if (b == NaN || b == null || typeof b != "number") {
            e = 0;
        } else {
            e = b;
        }
        if (c == NaN || c == null || typeof c != "number") {
            f = 0;
        } else {
            f = c;
        }
        return d + e + f;
    }

    // const exportTripletex = () => {
    //     var data = []
    //     for (var line in props.i.o.lines) {
    //         var lineData = {
    //             "ORDER NO": "OP" + props.i.o.id.toString(), 
    //             "ORDER DATE": props.i.o.date_of_order.split(" ")[0],
    //             "CUSTOMER NO": props.i.o.sub_customer[0].tripletexId,
    //             "DELIVERY DATE": props.i.o.date_of_pickup.split(" ")[0],
    //             "ORDER LINE - DESCRIPTION": props.i.o.lines[line].product.backstubeName,
    //             "ORDER LINE - UNIT PRICE": props.i.o.lines[line].netLinePrice / props.i.o.lines[line].quantity / props.i.o.lines[line].product.piecesPerQuantity,
    //             "ORDER LINE - COUNT": props.i.o.lines[line].actualQuantity * props.i.o.lines[line].product.piecesPerQuantity,
    //             "ORDER LINE - VAT CODE": props.i.o.lines[line].product.vatCode
    //         }
    //         data.push(lineData)
    //     }
    //     var freightLine = {
    //         "ORDER NO": "OP" + props.i.o.id.toString(), 
    //         "ORDER DATE": props.i.o.date_of_order.split(" ")[0],
    //         "CUSTOMER NO": props.i.o.sub_customer[0].tripletexId,
    //         "DELIVERY DATE": props.i.o.date_of_pickup.split(" ")[0],
    //         "ORDER LINE - DESCRIPTION": "Frakt",
    //         "ORDER LINE - UNIT PRICE": 0,
    //         "ORDER LINE - COUNT": 1,
    //         "ORDER LINE - VAT CODE": 3
    //     }
    //     data.push(freightLine)
    //     var df = new dfd.DataFrame(data)
    //     df.print()
    //     const csv = dfd.toCSV(df, { fileName: "Order " + props.i.o.id.toString() + ".csv", download: true})
    //}

    return(
        <AuthenticatedTemplate>
        <tr>
                                            <td onClick={handleShow} className="px-2">
                                                {props.i.product.backstube_name}
                                                <br />
                                                <i>GTIN: {props.i.product.gtin} | Art. nr.: {props.i.product.article_nr}</i>
                                            </td>
                                            <td className="px-2 text-center">{props.i.number_of_cartons}</td>
                                            <td className="px-2 text-center">{props.i.number_of_pallets}</td>
                                            <td className="px-2 text-center">{props.i.purchasePrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}</td>
                                            <td className="px-2 text-center">{props.i.rakPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                            <td className="px-2 text-center">{props.i.transportPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}) }</td>
                                            <td className="px-2 text-center">{(props.i.purchasePrice + props.i.rakPrice + props.i.transportPrice).toFixed(2) }</td>
                                            <td className="px-2 text-center">{((props.i.purchasePrice + props.i.rakPrice + props.i.transportPrice) / props.i.number_of_cartons).toFixed(2) }</td>
                                            {/* <td className="px-2 text-center">{props.i.product.råK_per_kilo}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.net_weight}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.råK_per_kilo / 1000 * props.i.product.net_weight}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.pieces_per_quantity}</td> */}
                                            {/* <td className="px-2 text-center">{(props.i.product.råK_per_kilo / 1000 * props.i.product.net_weight * props.i.product.pieces_per_quantity).toFixed(2)}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.net_weight}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.transport_cost_per_pallet}</td> */}
                                            {/* <td className="px-2 text-center">{props.i.product.cartons_per_pallet_inbound}</td> */}
                                            {/* <td className="px-2 text-center">{(props.i.product.transport_cost_per_pallet / props.i.product.cartons_per_pallet_inbound).toFixed(2)}</td> */}

                                            <Modal size="xl" show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton>
                <Modal.Title>{props.i.product.backstube_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                        <Card>
                                <Card.Header>Product information</Card.Header>
                                <ListGroup variant="flush">
                                    <ListGroupItem>
                                        Name: { props.i.product.name } // { props.i.product.backstube_name }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        GTIN-14: { props.i.product.gtin }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Art. nr.: { props.i.product.article_nr }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Nature of cargo: { props.i.product.natureOfCargo }
                                    </ListGroupItem>
                                    {props.i.product.natureOfCargo == "Frozen" &&
                                    <>
                                    <ListGroupItem>
                                        {props.i.product["råK_per_kilo"] &&
                                        <>
                                            RÅK per kilo: {props.i.product["råK_per_kilo"].toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                        </>
                                        }
                                        {!props.i.product["råK_per_kilo"] &&
                                        <>
                                            RÅK per kilo: <Badge bg="danger">Missing</Badge>
                                        </>
                                        }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        {props.i.product["net_weight"] &&
                                        <>
                                            Net weight / piece: {props.i.product["net_weight"].toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})} gram
                                        </>
                                        }
                                        {!props.i.product["net_weight"] && props.i.product.natureOfCargo == "Frozen" &&
                                        <>
                                            Net weight / piece: <Badge bg="danger">Missing</Badge>
                                        </>
                                        }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        {props.i.product.pieces_per_quantity &&
                                        <>
                                            Units / carton: {props.i.product.pieces_per_quantity.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                        </>
                                        }
                                        {!props.i.product.pieces_per_quantity &&
                                        <>
                                            Units / carton: <Badge bg="danger">Missing</Badge>
                                        </>
                                        }
                                    </ListGroupItem>
                                    </>
                                    }

                                    <ListGroupItem>
                                        {(props.i.product.transport_cost_per_pallet || props.i.product.transport_cost_per_pallet == 0) &&
                                        <>
                                            Transport cost / pallet: NOK {props.i.product.transport_cost_per_pallet.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                        </>
                                        }
                                        {(!props.i.product.transport_cost_per_pallet && props.i.product.transport_cost_per_pallet != 0) &&
                                            <span>Transport cost / pallet: <Badge bg="danger">Missing</Badge></span>
                                        }
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        {props.i.product.cartons_per_pallet_inbound &&
                                        <>
                                            Cartons / pallet, inbound: {props.i.product.cartons_per_pallet_inbound.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                        </>
                                        }
                                        {!props.i.product.cartons_per_pallet_inbound &&
                                        <>
                                            Cartons / pallet, inbound: <Badge bg="danger">Missing</Badge>
                                        </>
                                        }
                                    </ListGroupItem>
                                    
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                        <Card>
                                <Card.Header>Inventory value</Card.Header>
                                <ListGroup variant="flush">
                                    <ListGroupItem>
                                        Purchase price: NOK {props.i.purchasePrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        RÅK: NOK {props.i.rakPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Transport: NOK {props.i.transportPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Total value: NOK {(props.i.purchasePrice + props.i.rakPrice + props.i.transportPrice).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                    </ListGroupItem>
                                </ListGroup>
                            </Card>
                            <Card className="mt-3">
                                <Card.Header>Inventory value</Card.Header>
                                <ListGroup variant="flush">
                                    <ListGroupItem>
                                        # of cartons: {props.i.number_of_cartons.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                    </ListGroupItem>
                                    {props.i.number_of_pallets &&
                                        <ListGroupItem>
                                        # of pallets: {props.i.number_of_pallets.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                        </ListGroupItem>
                                    }

                                    
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    
                   
                    
                    {/* <h4>Order lines</h4> */}
                    <Card className="mt-3">
                        <Card.Header>
                            Batches - {props.i.statuses[0].stock_status.status_date}
                        </Card.Header>
                        <Card.Body>
                            <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th style={{background: '#fff'}}  colSpan="3" className="text-center">Price</th>
                                <th></th>
                                {props.i.product.natureOfCargo == "Frozen" &&

                                <th></th>
}
                                <th></th>
                                <th style={{background: '#f0f0f0'}} className="text-center" colSpan="2">Quantity</th>
                                <th style={{background: '#fff'}} className="text-center" colSpan="2">Value</th>

                            </tr>
                        </thead>
                        <thead>
                        <tr>
                                <th>Delivery ID and line ID</th>
                                <th>Voucher and invoice date</th>
                                <th style={{background: '#fff'}}  className="text-center">Price / carton</th>
                                <th style={{background: '#fff'}}  className="text-center">FX rate</th>
                                <th style={{background: '#fff'}}  className="text-center">NOK / carton</th>
                                {props.i.product.natureOfCargo == "Frozen" &&
                                <th className="text-center">RÅK / carton</th>
                                }
                                <th className="text-center">Transport / carton</th>
                                <th className="text-center">Total cost / carton</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">Received</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">On stock</th>
                                <th style={{background: '#fff'}} className="text-center">Received</th>
                                <th style={{background: '#fff'}} className="text-center">On stock</th>
                                
                            </tr>
                        </thead>

                           
                            
                        
                            <tbody>
                                
                                
                            {props.i.statuses.map(status => {
                                return (
                                    <tr>
                                        <td>{status.delivery.id} <br></br> {status.delivery_line.id}</td>
                                        <td>{status.delivery_line.invoice_voucher} <br></br> {moment(status.delivery_line.invoice_date).format("YYYY-MM-DD")}</td>
                                        <td style={{background: '#fff'}}  className="text-center">{status.delivery_line.currency} { parseFloat(status.delivery_line.price).toLocaleString(undefined, {maximumFractionDigits:2}) }</td>
                                        <td style={{background: '#fff'}}  className="text-center">
                                            {status.currency_rate &&
                                            <>
                                                <span>{status.currency_rate.currency}</span> <span>{ parseFloat(status.currency_rate.rate).toLocaleString(undefined, {maximumFractionDigits:2}) }</span>
                                            </>
                                            }
                                        </td>
                                        <td style={{background: '#fff'}}  className="text-center">
                                            {status.currency_rate &&
                                            <>
                                                <span>NOK </span> <span>{ parseFloat(status.currency_rate.rate * status.delivery_line.price).toLocaleString(undefined, {maximumFractionDigits:2}) }</span>
                                            </>
                                            }
                                        </td>
                                        {props.i.product.natureOfCargo == "Frozen" && props.i.product["råK_per_kilo"] && props.i.product.net_weight && props.i.product.pieces_per_quantity &&
                                             
                                                <>
                                                <td className="text-center">

                                                    NOK {(props.i.product["råK_per_kilo"] * props.i.product.net_weight / 1000 * props.i.product.pieces_per_quantity).toLocaleString(undefined, {maximumFractionDigits:2})}
                                                </td>
                                            
                                                
                                                </>

                                            
                                            
                                            }
                                        <td className="text-center">
                                            {props.i.product.transport_cost_per_pallet && props.i.product.cartons_per_pallet_inbound &&
                                             <>
                                                NOK {(props.i.product.transport_cost_per_pallet / props.i.product.cartons_per_pallet_inbound).toLocaleString(undefined, {maximumFractionDigits:2})}
                                            </>
                                            
                                            
                                            }
                                        </td>
                                        <td className="text-center">

                                        {status.currency_rate && status.delivery_line.price &&
                                        <>
                                            NOK {sumThree(
                                                status.currency_rate.rate*status.delivery_line.price,
                                                props.i.product.transport_cost_per_pallet/props.i.product.cartons_per_pallet_inbound,
                                                props.i.product["råK_per_kilo"]*props.i.product.net_weight/1000*props.i.product.pieces_per_quantity
                                                )
                                                .toLocaleString(undefined, {maximumFractionDigits:2})
                                            }

                                        </>
                                        }
                                            
                                            

                                        </td>
                                        <td style={{background: '#f0f0f0'}}  className="text-center">{status.delivery_line.quantity_pce_received}</td>
                                        <td style={{background: '#f0f0f0'}}  className="text-center">{status.stock_status.quantityLeft}</td>
                                        

                                        <td style={{background: '#fff'}}  className="text-center">
                                            {status.currency_rate &&
                                            <>
                                                <span>NOK </span> <span>{ parseFloat(status.delivery_line.quantity_pce_received * sumThree(
                                                status.currency_rate.rate*status.delivery_line.price,
                                                props.i.product.transport_cost_per_pallet/props.i.product.cartons_per_pallet_inbound,
                                                props.i.product["råK_per_kilo"]*props.i.product.net_weight/1000*props.i.product.pieces_per_quantity
                                                )).toLocaleString(undefined, {maximumFractionDigits:2}) }</span>
                                            </>
                                            }
                                        </td>
                                        <td style={{background: '#fff'}}  className="text-center">
                                            {status.currency_rate &&
                                            <>
                                                <span>NOK </span> <span>{ parseFloat(status.stock_status.quantityLeft * sumThree(
                                                status.currency_rate.rate*status.delivery_line.price,
                                                props.i.product.transport_cost_per_pallet/props.i.product.cartons_per_pallet_inbound,
                                                props.i.product["råK_per_kilo"]*props.i.product.net_weight/1000*props.i.product.pieces_per_quantity
                                                )).toLocaleString(undefined, {maximumFractionDigits:2}) }</span>
                                            </>
                                            }
                                        </td>
                                            
                                    </tr>
                                )
                            })}


                            </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                    
                    {/* {JSON.parse(props.i.order['order_data'])["products"].map((e) => {
                        return <p className="inter-font m-0 p-0" key={Math.random() * 100000}>{JSON.stringify(e).replaceAll('"', "").replaceAll('{', "").replaceAll('}', "")}</p>
                    })} */}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>                                        
                                        </tr>
        </AuthenticatedTemplate>
    )
}