import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { loginRequest } from "../../util/authConfig";
import { readString } from "react-papaparse";
import { Tooltip } from 'react-tooltip';
import InvoiceDateIcon from '../../images/icons/invoice-date.png';
import ReceivedDateIcon from '../../images/icons/received-date.png';
import QuantityIcon from '../../images/icons/quantity.png';
import QuantityOnStoreIcon from '../../images/icons/QuantityOnStore.png';
import InvoiceVoucherIcon from '../../images/icons/invoiceVoucher.png';
import PriceIcon from '../../images/icons/money-icon.png';
import SupplierIcon from '../../images/icons/supplier.png';
import FxRateIcon from '../../images/icons/fxrate.png';
import TransportPerCartonIcon from '../../images/icons/transportPerCarton.png';
import TotalTransportIcon from '../../images/icons/totalTransport.png';
import TotalValueIcon from '../../images/icons/Value.png';
import moment from "moment/moment";
import { Toast, ToastContainer } from "react-bootstrap";




export default function InboundOverview2(props) {
    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();

    // Data holders
    const [inboundDeliveryStockStatus, SetInboundDeliveryStockStatus] = useState();
    const [products, SetProducts] = useState();
    const [suppliers, SetSuppliers] = useState();
    const [valuta, SetValuta] = useState();
    const [valutaArray, SetValutaArray] = useState();
    const [modifiedLines, SetModifiedLines] = useState([]);
    
    // misc
    const [loading, setLoading] = useState(true);
    const [page, SetPage] = useState(0);
    const [perPage, SetPerPage] = useState(15);
    const [today, setToday] = useState(new Date());
    const [yesterday, setYesterday] = useState(() => {
        let d = new Date();
        d.setDate(d.getDate()-1);
        return d;
    })
    const [currencyAbbrs, setCurrencyAbbrs] = useState([
        "EUR",
        "NOK",
        "USD",
        "GBP",
        "SEK",
        "DKK"
    ])
    const [showToast, setShowToast] = useState(false);


    // DATA FETCHING
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])

    useEffect(() => {
        if(!token) return;
        let data = {
            "page": page,
            "perPage": perPage
        }
        fetch("./api/GetStockStatus", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
        })
        .then(data => {
            SetInboundDeliveryStockStatus([...data]);
            console.log(data);
        })
        .catch(err => {
            console.log("ERROR FETCHING STOCKSTATUS");
            console.log(err);
        })
    }, [token, page])

    useEffect(() => {
        if(!inboundDeliveryStockStatus) return;
        fetch("./api/GetAllProducts", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
        })
        .then(data => {
            SetProducts(data);
        })
        .catch(err => {
            console.log("ERROR FETCHING PRODUCTS");
            console.log(err);
        })

    }, [inboundDeliveryStockStatus])

    useEffect(() => {
        if(!products) return;
        fetch("./api/GetAllSuppliers", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            }
        })
        .then(data => {
            SetSuppliers(data);
        })
        .catch(err => {
            console.log("ERROR FETCHING SUPPLIERS");
            console.log(err);
        })
    }, [products])


    useEffect(() => {
        if(!suppliers) return;
        fetch("https://data.norges-bank.no/api/data/EXR/B.USD+EUR+SEK+DKK+GBP.NOK.SP?format=csv&startPeriod=2019-12-31&endPeriod="+today.toISOString()+"&locale=no&bom=include")
        .then(response => {
            if(response.ok) {
                return response.text();
            }
        })
        .then(data => {
            SetValuta(data);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
        })
    }, [suppliers])
 

    useEffect(() => {
        if(!valuta) return;
        readString(valuta, {
            worker: true,
            complete: (results) => {
                SetValutaArray(results.data)
            },
          });
    }, [valuta])

    useEffect(() => {
        if(!valutaArray) return;
        setLoading(false);
    }, [valutaArray])





    // FUNCTIONS
    function formatDateToYYYYMMDD(dateString) {
        const formats = [
          { format: /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/, order: ['DD', 'MM', 'YYYY', 'HH', 'mm', 'ss'] },
          { format: /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, order: ['MM', 'DD', 'YYYY', 'HH', 'mm', 'ss'] },
          { format: /(\d{4})\-(\d{2})\-(\d{2})/, order: ['YYYY', 'MM', 'DD'] }
        ];

        if(formats[2].format.test(dateString)) return dateString;
      
        let matchedFormat = null;
        for (const format of formats) {
          if (format.format.test(dateString)) {
            matchedFormat = format;
            break;
          }
        }
      
        if (!matchedFormat) {
          throw new Error('Invalid date format. Supported formats are "DD.MM.YYYY HH:mm:ss" and "MM/DD/YYYY HH:mm:ss".');
        }
      
        const [, ...matches] = dateString.match(matchedFormat.format);
        const dateObject = {};
      
        for (let i = 0; i < matchedFormat.order.length; i++) {
          dateObject[matchedFormat.order[i]] = matches[i];
        }
      
        const { YYYY, MM, DD } = dateObject;
        return dateString;
      }

      const findExchangeRate = (currency, date) => {
        if(!date) return;
        if(!valutaArray) return;
        if(!currency) return;
        if(currency == "N/A") return;
        if(currency == "NOK") return 1;

        let d = moment(date, "YYYY-MM-DD");

        if(d.isoWeekday() == 6) {
            d.subtract(1, "days")
        } else if(d.isoWeekday() == 7) {
            d.subtract(2, "days");
        }

        let rate;
        try {
            rate = valutaArray.find(o => o.includes(d.format("YYYY-MM-DD")) && o.includes(currency))[15];
            
        } catch (error) {
            console.log("Did not find rate for " + currency + " on " + date);
        }
        let i = 0;
        while(rate == null) {
            d.subtract(1, "days");
            try {
                rate = valutaArray.find(o => o.includes(d.format("YYYY-MM-DD")) && o.includes(currency))[15];
            } catch {}
            i++;
            if(i > 3) return -1;
        }
        rate = rate.replace(/,/, '.')
        rate = parseFloat(rate)
        if(currency == "SEK" || currency == "DKK") {
            rate = rate / 100;
        }
        return rate;
    }

    function UpdateModifiedLine(parameter, id, row) {
        console.log("RAN");
        let element = document.getElementById(id);
        let value;
        switch(parameter) {
            case "invoice_date":
                value = element.value;
                console.log("DATE VALUE", value)
                if(value) row.line.invoice_date = element.value;
                if(!modifiedLines.includes(row)) SetModifiedLines([...modifiedLines, row]);
                break;
            case "invoice_voucher":
                value = element.value;
                if(value) row.line.invoice_voucher = element.value;
                if(!modifiedLines.includes(row)) SetModifiedLines([...modifiedLines, row]);
                break;
            case "price":
                value = element.value;
                if(value) row.line.price = element.value;
                if(!modifiedLines.includes(row)) SetModifiedLines([...modifiedLines, row]);
                break;
            case "currency":
                value = element.options[element.selectedIndex].value;
                if(value == "N/A") return;
                if(value) row.line.currency = element.value;
                if(!modifiedLines.includes(row)) SetModifiedLines([...modifiedLines, row]);
                break;
            default:
                console.log("parameter not found!");
        }
    }

    // Save changes to backend.
    function UploadModifications() {
        
        let changedRows = [];
        modifiedLines.forEach(obj => {
            changedRows.push(obj.line)
        });

        console.log(changedRows)
        fetch("./api/UpdateInboundDeliveryLines", {
            method: "POST",
            body: JSON.stringify(changedRows),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => {
            if(response.ok) {
                console.log("Upload Success!");
                setShowToast(true);
            }
            
        })
        .catch(err => {
            console.log("Error uploading modified rows.")
            console.log(err)
        })
    }

    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const nextPage = () => {
        SetPage(page + 1);
    }

    const previousPage = () => {
        if(page == 0) return;
        SetPage(page - 1);
    }

    const GoToPage = () => {
        let value = document.getElementById("pageDisplay").value;
        if(value[0] == "-") return;
        if(value == null) return;
        if(value < 1) return;
        console.log("flag")
        
        SetPage(value-1);
    }

    const calculateTotalRåk = (row, type) => {
        let product = products.find(p => p.id==row.line.product_ref);
        if(product.råK_per_kilo == null || product.net_weight == null || product.pieces_per_quantity == null) return 0;
        
        if(!product) {
            console.error("ERROR IN RÅK CALC FOR ROW " + row.line.id + " | Delivery " + row.delivery.id + " | Product database id " + row.line.product_ref);
            return;
        } 
        
        let råkPerKilo = product.råK_per_kilo;
        if(typeof råkPerKilo != "number") {
            console.error("ERROR IN RÅK CALC. NOT A NUMBER! råkPerKilo")
            return;
        }  
        let productNetWeight = product.net_weight;
        if(typeof productNetWeight != "number") {
            console.error("ERROR IN RÅK CALC. NOT A NUMBER! productNetWeight")
            return;
        }

        let productPiecesPerQuantity = product.pieces_per_quantity;
        if(typeof productPiecesPerQuantity != "number") {
            console.error("ERROR IN RÅK CALC. NOT A NUMBER! productPiecesPerQuantity")
            return;
        }

        let råkPerCarton = parseFloat(råkPerKilo / 1000 * productNetWeight * productPiecesPerQuantity).toFixed(4)
        let value = 0;
        // received
        if(type === 0) {
            value = råkPerCarton * row.line.quantity_pce_received;
        } 
        // storage
        if(type === 1) {
            value = råkPerCarton * row.status.quantityLeft;
        }
        return value;
    }

    
    const calculateTransportCostPerCarton = (row, type) => {
        let productCostPerQuantity = products.find(p => p.id == row.line.product_ref).transport_cost_per_pallet;
        if(productCostPerQuantity == null) {
            return 0.00;
        }

        if(productCostPerQuantity == 0) return 0;
        

        let quantity;
        // Received
        if(type === 0) {
            quantity = row.line.quantity_pce_received 
        }
        // Storage
        if(type === 1) {
            quantity = row.status.quantityLeft
        }
        if(quantity == 0) return 0;
        // products.find(p => p.id==row.line.product_ref).transport_cost_per_pallet / row.line.quantity_pce_received).toFixed(2) * row.status.quantityLeft
        return parseFloat(productCostPerQuantity / quantity).toFixed(2);
    }

    const calcualteTransportCost = (row, type) => {
        let quantity;
        let perCarton;
        //received
        if(type === 0) {
            quantity = row.line.quantity_pce_received
            perCarton = calculateTransportCostPerCarton(row, 0)
        }
        if(type === 1) {
            quantity = row.status.quantityLeft
            perCarton = calculateTransportCostPerCarton(row, 1)
        }
        

        return parseFloat(perCarton * quantity).toFixed(2)
    }

    const calculateTotalValue = (row, type) => {
        if(row.status.quantityLeft == null || row.line.price == null || row.line.currency == null) return 0;
        let quantity;
        if(type === 0) quantity = row.line.quantity_pce_received
        if(type === 1) quantity = row.status.quantityLeft
        let price = row.line.price;
        let currencyAbbr = document.getElementById(row.line.id + "_currency")?.options[document.getElementById(row.line.id + "_currency").selectedIndex].value ? document.getElementById(row.line.id + "_currency")?.options[document.getElementById(row.line.id + "_currency").selectedIndex].value : row.line.currency;
        let invoice_date = row.line.invoice_date;
        let totalRåk = calculateTotalRåk(row, 0);
        if(currencyAbbr == "") {
            console.log("ERROR IN calculateTotalStorageQuantityValue: currencyAbbr is empty") ;
        }
        if(invoice_date == "") {
            console.log("ERROR IN calculateTotalStorageQuantityValue: invoice_date is empty");
        }
        let fxRate = findExchangeRate(currencyAbbr, invoice_date);

        let transportCost = calcualteTransportCost(row, type);
        let value = parseFloat(  
            parseInt(quantity) * parseFloat(price) * parseFloat(fxRate) + parseFloat(totalRåk) + parseFloat(transportCost)
         ).toFixed(2)
        return value;
    }

    //DEBUG
    useEffect(() => {
        console.log(modifiedLines)
        console.log(products)
        modifiedLines.forEach(ml => {
        })
    }, [modifiedLines])
    


    // Check loading state
    if(loading) {
        return(
            <div className="container vh-100 d-flex justify-content-center align-items-center">
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#3e3e3e" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                        />
                </div>
        )
    }

    return(
        <div className="container d-flex justify-content-center align-items-center flex-column">
            <h3>Inbound Overview</h3>
            <div className="col-6 d-flex justify-content-center align-items-center">
                <button className="btn btn-dark col-4 m-2" onClick={previousPage}>Previous page</button>
                <input key={page} className="form-control m-2 text-center" style={{"width": "50px"}} type="number" placeholder={page +1} defaultValue={page +1} onChange={GoToPage} id="pageDisplay"></input>
                <button className="btn btn-dark col-4 m-2" onClick={nextPage}>Next page</button>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
                {modifiedLines.length > 0 && 
                    <button className="btn btn-success col-4 m-2" onClick={UploadModifications}>Save Changes</button>
                }
            </div>

                {inboundDeliveryStockStatus.length > 0 && 
                <div className="table-responsive">
                    <table className="table table-striped table-bordered text-center">
                        <thead>
                            <tr>
                                    <th  scope="col" id="deliveryId" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#deliveryId" place="top">
                                        Delivery Database ID
                                    </Tooltip>D#</th>

                                    

                                    <th  scope="col" id="invoiceDate" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#invoiceDate" place="top">
                                        Invoice Date
                                    </Tooltip><img src={InvoiceDateIcon} width="35px" className="m-3" alt="" /></th>

                                    <th  scope="col" id="quantity" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#quantity" place="top">
                                        Quantity received
                                    </Tooltip><img src={QuantityIcon} width="35px" alt="" className="m-3" /></th>

                                    <th  scope="col" id="receivedDate" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#receivedDate" place="top">
                                        Received Date
                                    </Tooltip><img src={ReceivedDateIcon} width="35px" className="m-3" alt="" /></th>

                                    <th  scope="col" id="QuantityOnStore" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#QuantityOnStore" place="top">
                                        Quantity On Store
                                    </Tooltip><img src={QuantityOnStoreIcon} width="35px" className="m-3" alt="" /></th>

                                    <th  scope="col" id="InvoiceVoucher" style={{"minWidth": "120px"}}>
                                    <Tooltip anchorSelect="#InvoiceVoucher" place="top">
                                        Invoice Voucher
                                    </Tooltip><img src={InvoiceVoucherIcon} width="30px" className="m-3" alt="" /></th>

                                    <th  scope="col" id="Price" style={{"minWidth": "100px"}}>
                                    <Tooltip anchorSelect="#Price" place="top">
                                        Price in purchase currency
                                    </Tooltip><img src={PriceIcon} width="30px" className="m-3" alt="" /></th>
                                    
                                    <th scope="col" id="Currency" style={{"minWidth": "65px"}}>
                                    <Tooltip anchorSelect="#Currency" place="top">
                                        Currency
                                    </Tooltip>C</th>

                                    <th  scope="col" id="Supplier" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#Supplier" place="top">
                                        Supplier
                                    </Tooltip><img src={SupplierIcon} width="30px" className="m-3" alt="" /></th>

                                    <th scope="col" id="Product" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#Product" place="top">
                                        Product name
                                    </Tooltip>P</th>

                                    <th scope="col" id="articleId" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#articleId" place="top">
                                        Article ID
                                    </Tooltip>A#</th>
                                    
                                    <th scope="col" id="fxRate" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#fxRate" place="top">
                                        Relevant Exchange Rate
                                    </Tooltip><img src={FxRateIcon} width="30px" className="m-3" alt="" /></th>

                                    <th scope="col" id="totalPurchasePrice" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#totalPurchasePrice" place="top">
                                        Total Purchase Price in NOK (price * quantity received * fxRate)
                                    </Tooltip>TPP</th>

                                    <th scope="col" id="RÅKPerCarton" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#RÅKPerCarton" place="top">
                                        RÅK per carton
                                    </Tooltip>RPC</th>

                                    <th scope="col" id="TransportPerCarton" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#TransportPerCarton" place="top">
                                        Total Transport Per Carton
                                    </Tooltip><img src={TransportPerCartonIcon} width="20px" className="m-3" alt="" /></th>

                                    <th scope="col" id="TotalTransportCost" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#TotalTransportCost" place="top">
                                        Total storage volume transport cost
                                    </Tooltip><img src={TotalTransportIcon} width="40px" className="m-3" alt="" /></th>

                                    <th scope="col" id="TotalValueReceived" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#TotalValueReceived" place="top">
                                        Total Value Received
                                    </Tooltip><img src={TotalValueIcon} width="35px" className="m-3" alt="" /></th>

                                    <th scope="col" id="TotalValue" style={{"minWidth": "40px"}}>
                                    <Tooltip anchorSelect="#TotalValue" place="top">
                                        Total Value Of Storage Quantity
                                    </Tooltip><img src={TotalValueIcon} width="35px" className="m-3" alt="" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {inboundDeliveryStockStatus && 
                                inboundDeliveryStockStatus.sort((b,a) => a.line.id - b.line.id).map(row => {
                                    let modified = false;
                                    if(modifiedLines.length > 0 && modifiedLines.find(r => row.line.id == r.line.id)){
                                        row = modifiedLines.find(r => r.line.id == row.line.id);
                                        modified = true;
                                    }
                                    return(
                                        <tr key={row.line.id} className={modified ? "text-success" : ""}>
                                            {/* DELIVERY ID */}
                                            <td scope="col">{row.delivery.id}</td>
                                            {/* INVOICE DATE */}
                                            <td scope="col"><input type="date" className="form-control" max={yesterday.toISOString().split('T')[0]} defaultValue={row.line.invoice_date ? formatDateToYYYYMMDD(row.line.invoice_date) : ""} id={row.line.id + "_invoicedate"} onChange={() => UpdateModifiedLine("invoice_date", row.line.id + "_invoicedate", row)} /></td>
                                            {/* QUANTITY RECEIVED */}
                                            <td scope="col">{row.line.quantity_pce_received ? row.line.quantity_pce_received : "N/A"}</td>
                                            {/* DELIVERY DATE */}
                                            <td scope="col"><small>{row.delivery.date_of_delivery ? formatDateToYYYYMMDD(row.delivery.date_of_delivery) : "N/A"}</small></td>
                                            {/* QUANTITY ON STORAGE */}
                                            <td scope="col">{row.status ? row.status.quantityLeft : "N/A"}</td>
                                            {/* INVOICE VOUCHER */}
                                            <td scope="col"><input type="text" className="form-control" defaultValue={row.line.invoice_voucher ? row.line.invoice_voucher : ""} id={row.line.id + "_invoicevoucher"} onChange={() => UpdateModifiedLine("invoice_voucher", row.line.id + "_invoicevoucher", row) }/></td>
                                            {/* PRICE IN PURCHASE CURRENCY */}
                                            <td scope="col"><input type="number" className="form-control" defaultValue={row.line.price ? row.line.price : "N/A"} id={row.line.id + "_price"} onChange={() => UpdateModifiedLine("price", row.line.id + "_price", row) }/></td>
                                            {/* CURRENCY */}
                                            <td scope="col" className="col-1" style={{"minWidth": "80px"}}>
                                            {
                                                <select className="form-control" id={row.line.id + "_currency"} onChange={() => UpdateModifiedLine("currency", row.line.id + "_currency", row) } >
                                                    {row.line.currency && <option value={row.line.currency}>{row.line.currency}</option>}
                                                    {!row.line.currency && <option value="N/A">N/A</option>}
                                                    {currencyAbbrs.map(crncy => {
                                                        if(crncy != row.line.currency) return <option value={crncy}>{crncy}</option>
                                                    })}
                                                </select>
                                            }
                                            </td>
                                            {/* SUPPLIER */}
                                            <td scope="col">{suppliers.find(s => s.id == row.delivery.supplier).name}</td>
                                            {/* PRODUCT NAME */}
                                            <td scope="col" style={{"minWidth": "200px"}}><small>{products.find(p => p.id == row.line.product_ref).backstube_name}</small></td>
                                            {/* ARTICLE ID */}
                                            <td scope="col">{products.find(p=> p.id == row.line.product_ref).article_nr}</td>
                                            {/* RELEVANT EXCHANGE RATE */}
                                            <td scope="col">{row.line.invoice_date ? parseFloat(findExchangeRate(document.getElementById(row.line.id + "_currency")?.options[document.getElementById(row.line.id + "_currency").selectedIndex].value, formatDateToYYYYMMDD(row.line.invoice_date))).toFixed(2) : "N/A"}</td>
                                            {/* TOTAL PURCHASE PRICE IN NOK */}
                                            <td scope="col">{row.line.price && row.line.quantity_pce_received && row.line.invoice_date ? 
                                                parseFloat((row.line.price * parseFloat(findExchangeRate(document.getElementById(row.line.id + "_currency")?.options[document.getElementById(row.line.id + "_currency").selectedIndex].value, formatDateToYYYYMMDD(row.line.invoice_date))).toFixed(2)) * row.line.quantity_pce_received).toFixed(2) :
                                                "N/A"
                                            }</td>

                                            {/* RÅK PER CARTON */}
                                            <td scope="col">{calculateTotalRåk(row, 1)}</td>
                                            {/* TRANSPORT COST PER CARTON */}
                                            <td scope="col">{calculateTransportCostPerCarton(row, 0)}</td>
                                            {/* TOTAL STORAGE VOLUME TRANSPORT COST */}
                                            <td scope="col">{calcualteTransportCost(row, 1)}</td>

                                            {/* TOTAL VALUE RECEIVED */}
                                            <td scope="col">{calculateTotalValue(row, 0)}</td>

                                            {/* TOTAL VALUE ON STORAGE */}
                                            <td scope="col">{calculateTotalValue(row, 1)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                }
                {inboundDeliveryStockStatus.length <= 0 && 
                    <p className="m-5 text-secondary">You have reached the end of the list.</p>
                }
                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto text-success">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>Your changes have been saved.</Toast.Body>
                    </Toast>
                </ToastContainer>
        </div>
    )


}