import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import FilterSingleSelect from "../../components/FilterSingleSelect";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import NavigationBar from "../../modules/NavigationBar";
import { loginRequest } from "../../util/authConfig";


export default function Allergens() {

    const [filterActive, setFilterActive] = useState(true)
    const [selectedAllergens, setSelectedAllergens] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedAllergenType, setSelectedAllergenType] = useState([])
    const [allergens, setAllergens] = useState()
    const [products, setProducts] = useState()
    const [allergenTypes, setAllergenTypes] = useState()
    const [subCustomers, SetSubCustomers] = useState();
    const [contactPersons, SetContactPersons] = useState();
    const [selectedElement, SetSelectedElement] = useState();
    const [selectedElementType, SetSelectedElementType] = useState();
    const [isCreateMode, SetIsCreateMode] = useState(false);
    const [createElement, SetCreateElement] = useState();
    const [newElement, SetNewElement] = useState();
    const [refresh, SetRefresh] = useState();
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();


    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    useEffect(() => {
        if(!token) return;

        fetch("./api/GetAllAllergens",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            setAllergens(data);
            // var products = []
            // for (var d in data){
            //     if (data[d]['product'] in products) {
            //         console.log('already there')
            //     } else {
            //         products.push(data[d]['product'])
            //     }
            // }
            // setProducts(products);
        });



        fetch("./api/GetAllProducts",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            setProducts(data);
        });

        fetch("./api/GetAllAllergenTypes",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            let allergenTypes = data.map(at => {
                return {
                    "name": at.name_no,
                    "id": at.id
                }
            })
            setAllergenTypes(allergenTypes);
        });

    }, [token, refresh]);



    // let products = data.sort((a, b) => a.backstube_order - b.backstube_order).filter(item => item.natureOfCargo === props.natureOfCargo).map(filteredItem => {
    //     return{
    //         "procured_product": {
    //             "name": filteredItem.pname,
    //             "backstube_name": filteredItem.backstube_name,
    //             "GTIN": filteredItem.gtin,
    //             "Id": filteredItem.product_id,
    //             "article_nr": filteredItem.article_nr,
    //         },
    //         "amount": "",
    //         "pallets": 0,
    //         "net_price_per_quantity": filteredItem.net_price_per_quantity,
    //         "note_1": filteredItem.note_1,
    //         "note_2": filteredItem.note_2,
    //         "pieces_per_quantity": filteredItem.pieces_per_quantity,
    //         "box_factor": filteredItem.box_factor
    //     }


    function handleDelete(id) {
        fetch("./api/DeleteAllergen", {
            method: "POST",
            body: JSON.stringify(id),
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
        .then((response) => {
            if(!response.ok) {
                SetToastIsError(true);
            } else {
                SetToastIsError(false);
            }
            return response.text();   
        })
        .then((data) => {
            SetRefresh(Math.random()*500000);
            SetToastMessage(data);
            setShowToast(true);
            handleClose();
        })
        console.log("Sent update to backend")
    }

    const handleClick = (element, type) => {
        SetModalErrorMsg(null);
        SetIsCreateMode(false);
        SetSelectedElementType(type);
        SetSelectedElement(element);
        setShow(true);
    }

    const handleSend = () => {
        let element;
        let url;
        let valid = true;
        if(!isCreateMode) {
            switch(selectedElementType) {
                case "ALLERGEN":
                    element = {
                        "id": selectedElement.id,
                        "name": document.getElementById("name").value ? document.getElementById("name").value : selectedElement.name,
                        "active": document.getElementById("active").value ? document.getElementById("active").value : selectedElement.active
                        // "contact_person": document.getElementById("contactperson").value ? document.getElementById("contactperson").value : selectedElement.contact_person
                    }
                    if(element.name == "" || element.active == "") valid = false;
                    url = "./api/UpdateAllergen";
                    break;
    
            }
        } else {
            switch(selectedElementType) {
                case "ALLERGEN":
                    if(selectedProduct[0] === undefined || selectedAllergenType[0] === undefined) {
                        valid = false;
                    } else {
                        element = {
                            "product_id": selectedProduct[0].id,
                            "allergen_type": selectedAllergenType[0].id,
                        }
                        console.log(element)
                        console.log(selectedAllergenType[0].id)
                        console.log(selectedProduct[0].id)
                    }
                    
                    
                    url = "./api/CreateAllergen";
                    break;
            }
        }
        if(valid) {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(element),
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
            .then((response) => {
                if(!response.ok) {
                    SetToastIsError(true);
                } else {
                    SetToastIsError(false);
                }
                return response.text();   
            })
            .then((data) => {
                SetRefresh(Math.random()*500000);
                SetToastMessage(data);
                setShowToast(true);
                handleClose();
            })
            console.log("Sent update to backend")
            
        } else {
            console.log("not valid");
            SetModalErrorMsg("One or more fields are not valid!");
        }
    }

    const handleCreate = (type) => {
        SetModalErrorMsg(null);
        SetSelectedElement(null);
        console.log(type)
        SetIsCreateMode(true);
        setShow(true);
        SetSelectedElementType(type);
    }

    return(
        
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <Col>
                        <h2 className="my-0">Allergens</h2>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <button className="btn btn-success my-2 mx-2" onClick={() => handleCreate("ALLERGEN")}>Create allergen</button>
                    </Col>
                </Row>
                    <Row className="mt-4">
                    <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)}></FilterMultipleSelect>    
                        {/* <Form>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    onChange={setFilterActive(!filterActive)}
                                    label="Active"
                                    name="group1"
                                    type="switch"
                                    id={`inline-${type}-1`}
                                    checked
                                />
                                <Form.Check
                                    inline
                                    label="Unactive"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                />
                                </div>
                            ))}
                    </Form> */}
                    </Card.Header>
                    <Card.Body className="p-0 m-0">
                    {allergens &&
                        <Table striped bordered className="m-0">
                            <thead>
                                <tr>
                                    <th className="text-center fw-bold">ID</th>
                                    <th className="fw-bold">Name</th>
                                    <th className="text-center fw-bold">Allergen</th>
                                    <th className="text-center fw-bold">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allergens.filter(function (s) {
                                        if(selectedProducts.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sp in selectedProducts) {
                                                if (selectedProducts[sp].id == s.product.id) {
                                                    return true
                                                }
                                            }
                                            return selectedProducts.includes(s.product)
                                        }
                                    }).map(i => {
                                    return(
                                        <tr key={i.id}>
                                            <td className="text-center">{i.id}</td>
                                            <td>{i.product.name}</td>
                                            <td className="text-center">{i.allergen_type}</td>
                                            <td className="d-flex justify-content-center"><Button onClick={() => handleDelete(i.id)} variant="danger">Delete</Button></td>
                                            {/* <td className="text-center">{i.active == 1 && <Badge pill bg="success">Active</Badge>}{i.active == 0 && <Badge pill bg="secondary">Inactive</Badge>}</td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    </Card.Body>
                </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {selectedElement && selectedElementType == "ALLERGEN" &&
                    <>
                        <label for="name">Name</label>
                        <input id="name" className="form-control" type="text" placeholder={selectedElement.name} />
                        <label for="active">Active</label>
                        <input id="active" className="form-control" type="text" placeholder={selectedElement.active} />
                        {/* <label for="contactperson">Contact Person</label> */}
                        {/* <select name="contactperson" className="form-control" id="contactperson">
                            {contactPersons.map(person => {
                                if(selectedElement.contact_person == person.id) {
                                    return <option value={person.id} selected="selected">{person.name}</option>
                                }
                                return <option value={person.id}>{person.name}</option>
                            })}
                        </select> */}
                    </>
                    }
                    
                    {isCreateMode && selectedElementType == "ALLERGEN" &&
                    <>
                        <label for="name">Name</label>
                        <FilterSingleSelect name_of_filtered="product" selected={selectedProduct} complete_object={products} onChange={(return_data) => setSelectedProduct(return_data)}></FilterSingleSelect>
                        {/* <FilterMultipleSelect name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)}></FilterMultipleSelect>     */}
                        <label for="name">Name</label>
                        <FilterSingleSelect name_of_filtered="allergen" selected={selectedAllergenType} complete_object={allergenTypes} onChange={(return_data) => setSelectedAllergenType(return_data)}></FilterSingleSelect>


                        {/* <input id="name" className="form-control" type="text" /> */}
                        {/* <label for="orgnr">Org #</label> */}
                        {/* <input id="orgnr" className="form-control" type="text" /> */}
                        {/* <label for="contactperson">Contact Person</label> */}
                        {/* <select name="contactperson" className="form-control" id="contactperson"> */}
                            {/* {contactPersons.map(person => { */}
                                {/* return <option value={person.id} key={person.id}>{person.name}</option> */}
                            {/* })} */}
                        {/* </select> */}
                    </>
                    }
                    
                    {modalErrorMsg &&
                    <p className="text-danger">{modalErrorMsg}</p>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSend}>Save</Button>
                    </Modal.Footer>
            </Modal>
        
        </Row>
    </Container>   
                
        </AuthenticatedTemplate>
    </div>
    )
}