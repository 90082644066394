import { useEffect, componentDidMount, useState } from "react";
import { GetToken } from "../../util/GetToken";
import { loginRequest } from "../../util/authConfig";
import { useMsal } from "@azure/msal-react";


export default function Test() {
    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])
    


    if(!token) {
        return(
            <h2>Loading...</h2>
        )
    }

    fetch("./api/test", {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.text())
    .then(data => console.log(data)); 

    return(
        <>
        <h2>Hello</h2>
        </>
    );
}