import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
// import { loginRequest } from "../authConfig";
import { loginRequest } from "../util/authConfig";
import { useState } from 'react';
import { silentRequest } from "../util/authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchGroups();
    }
  }, [isAuthenticated]);

  async function fetchGroups() {
    try {
      const accessToken = await instance.acquireTokenSilent(silentRequest);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
        headers: {
          'Authorization': 'Bearer ' + accessToken.accessToken
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        throw new Error('Error fetching groups');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleLoginRedirect = async () => {
    await instance.loginRedirect(loginRequest);
    window.location.href = "/";
  }

  const handleLogin = async (loginType) => {
    try {
      setLoading(true);
      if (loginType === "popup") {
        await instance.loginPopup(loginRequest);
        window.location.href = "/";
      } else if (loginType === "redirect") {
        await instance.loginRedirect(loginRequest)
        .then(() => {
          window.location.href = "/";
        });
      }
  
      const userGroups = await fetchGroups(); // Fetch user groups after login
      console.log("User Groups:", userGroups); // Log the user groups data
  
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
        <input
          type="submit"
          onClick={() => handleLogin("redirect")}
          className={
            !loading
              ? "form-control btn-outline-primary"
              : "form-control btn-outline-secondary"
          }
          value="Sign in"
        />
  );
};