import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import { ButtonToolbar } from 'react-bootstrap';
import { useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';


 

export default function FilterMultipleSelect({name_of_filtered, selected, complete_object, onChange}) {
  // const PublicMethodsExample = () => {
    const ref = useRef();
    // function update(list) {
    //   onChange(selected)
    // }
    const placeholder = "Choose a ".concat(name_of_filtered)
    return (
      <>
      <link rel="stylesheet" href="https://unpkg.com/react-bootstrap-typeahead/css/Typeahead.css"/>
      <InputGroup>
          <InputGroup.Text>{name_of_filtered.charAt(0).toUpperCase() + name_of_filtered.slice(1)}</InputGroup.Text>
        <Typeahead
          id="public-methods-example"
          labelKey="name"
          multiple
          options={complete_object}
          placeholder={placeholder}
          ref={ref}
          onChange={(selected) => {
            onChange(selected)
          }}
          selected={selected}
        />
        </InputGroup>
      </>
    );
}