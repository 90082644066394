import Allergens from "./Allergens";


export default function AllergensManager() {

    return(
        
        <Allergens />
        
    )
}