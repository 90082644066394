import React from "react";
import { useMsal } from "@azure/msal-react";

const ProtectedRoute = ({ allowedGroups, userGroups, children }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  if (!activeAccount) {
    window.location.href = "/login";
    return null;
  }

  // Sjekker om bruker har tilgang til siden
  const userIsAllowed = userGroups.some((group) =>
    allowedGroups.some((allowedGroup) => group.displayName === allowedGroup)
  );

  if (!userIsAllowed) {
    window.location.href = "/not-allowed";
    return null;
  } 

  return <>{children}</>;
};

export default ProtectedRoute;