import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import NavigationBar from "../../modules/NavigationBar";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";

export default function SubCustomerAccess() {
    const [APIPayload, setAPIPayload] = useState([])
    const [createRelationPrice, setCreateRelationPrice] = useState()
    const [createRelationChosenDate, setCreateRelationChosenDate] = useState()
    const [filterActive, setFilterActive] = useState(true)
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedSubCustomers, setSelectedSubCustomers] = useState([])
    const [selectedCreateSubCustomers, setSelectedCreateSubCustomers] = useState([])
    const [selectedCreateUsers, setSelectedCreateUsers] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [subCustomerAccess, SetSubCustomerAccess] = useState()
    const [customers, SetCustomers] = useState();
    const [users, SetUsers] = useState();
    const [subCustomers, SetSubCustomers] = useState();
    const [products, SetProducts] = useState();
    const [contactPersons, SetContactPersons] = useState();
    const [selectedElement, SetSelectedElement] = useState();
    const [selectedElementType, SetSelectedElementType] = useState();
    const [isCreateMode, SetIsCreateMode] = useState(false);
    const [createElement, SetCreateElement] = useState();
    const [newElement, SetNewElement] = useState();
    const [refresh, SetRefresh] = useState();
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();


    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])



    function handleCreateRelationChosenDate(event){
        const {value} = event.target;
        setCreateRelationChosenDate(value);
    }
    function handleUpdateCreateRelationPrice(event) {
        const {value} = event.target;
        // console.log(value)       
        const validateValue = /^\d*\.?\d*$/; // decimal number regex
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        // console.log(valueOK)
        // if (valueOK == true) {
        //     setCreateRelationPrice(value)
        // }
        // constructAPIPayload()
        setCreateRelationPrice(value)
    }

    function validateCreate(){
        if(selectedCreateSubCustomers.length == 0) {return false};
        if(selectedCreateUsers.length == 0) {return false};
        if(createRelationChosenDate == "") {return false};
        if(createRelationPrice == "") {return false};
        return true;
    }

    function constructAPIPayload(){
        var payload = []
        for (var sub_customer in selectedCreateSubCustomers) {
            for (var user in selectedCreateUsers) {
                var item = {
                    "sub_customer": selectedCreateSubCustomers[sub_customer].id,
                    "user": selectedCreateUsers[user].id
                }
                payload.push(item)
            }
        }
        return payload
    }

    function handleCreateNewRelation(){
        if (!validateCreate){
            return false; // user needs to fill data
        };
        fetch("./api/CreateMultipleSubCustomerAccesses", {
            method: "POST",
            body: JSON.stringify(constructAPIPayload()),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(!response.ok) {
                SetToastIsError(true);
            } else {
                SetToastIsError(false);
            }
            return response.text();   
        })
        .then((data) => {
            SetRefresh(Math.random()*500000);
            SetToastMessage(data);
            setShowToast(true);
            handleClose();
        })
        // setAPIPayload(constructAPIPayload());
    }
 
    // const handleSetSelectedCustomers = (return_data) => {
    //     console.log(return_data)
    //     var new_array = []
    //     for (var d in return_data) {
    //         new_array.push(return_data[d].id)
    //     }
    //     setSelectedCustomers(new_array)
    // }

    useEffect(() => {
        if(!token) return;

        fetch("./api/GetAllSubCustomerAccess",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetSubCustomerAccess(data);
        });

        fetch("./api/GetAllCustomers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetCustomers(data);
        });

        fetch("./api/GetAllSubCustomers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetSubCustomers(data);
        });

        fetch("./api/GetAllUsers",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetUsers(data);
        });

        fetch("./api/GetAllContactPersons",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetContactPersons(data);
        });

    }, [token, refresh]);


    const handleClick = (element, type) => {
        SetModalErrorMsg(null);
        SetIsCreateMode(false);
        SetSelectedElementType(type);
        SetSelectedElement(element);
        setShow(true);
    }

    function handleDelete(id) {
        fetch("./api/DeleteSubCustomerAccess", {
            method: "POST",
            body: JSON.stringify(id),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            }
        })
        .then((response) => {
            if(!response.ok) {
                SetToastIsError(true);
            } else {
                SetToastIsError(false);
            }
            return response.text();   
        })
        .then((data) => {
            SetRefresh(Math.random()*500000);
            SetToastMessage(data);
            setShowToast(true);
            handleClose();
        })
        console.log("Sent update to backend")
    }

    const handleSend = () => {
        let element;
        let url;
        let valid = true;
        if(!isCreateMode) {
            switch(selectedElementType) {
                case "SUBCUSTOMERACCESS":
                    element = {
                        "id": selectedElement.id,
                        "start_date": document.getElementById("date").value ? document.getElementById("date").value : selectedElement.name,
                        "active": document.getElementById("active").value ? document.getElementById("active").value : selectedElement.active
                    }
                    url = "./api/UpdateSUBCUSTOMERACCESS";
                    console.log(element);
                    break;
            }
        } else {
            switch(selectedElementType) {
                case "SUBCUSTOMERACCESS":
                    return handleCreateNewRelation()
                    // element = {
                    //     "name": document.getElementById("name").value,
                    //     // "orgnr": document.getElementById("orgnr").value,
                    //     // "contact_person": document.getElementById("contactperson").value
                    // }
                    // if(element.name == "") valid = false;
                    // url = "./api/CreateSupplier";
                    break;
            }
        }
        if(valid) {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(element),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then((response) => {
                if(!response.ok) {
                    SetToastIsError(true);
                } else {
                    SetToastIsError(false);
                }
                return response.text();   
            })
            .then((data) => {
                SetRefresh(Math.random()*500000);
                SetToastMessage(data);
                setShowToast(true);
                handleClose();
            })
            console.log("Sent update to backend")
            
        } else {
            console.log("not valid");
            SetModalErrorMsg("One or more fields are not valid!");
        }
    }

    const handleCreate = (type) => {
        SetModalErrorMsg(null);
        SetSelectedElement(null);
        console.log(type)
        SetIsCreateMode(true);
        setShow(true);
        SetSelectedElementType(type);
    }

    return(
        
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex align-items-center">
                        <h2 className="my-0">Sub Customer Access</h2>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <button className="btn btn-success my-2 mx-2" onClick={() => handleCreate("SUBCUSTOMERACCESS")}>Add new access</button>
                    </Col>
                </Row>
                    <Row className="mt-4">
                    <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="customer" selected={selectedCustomers} complete_object={customers} onChange={(return_data) => setSelectedCustomers(return_data)}></FilterMultipleSelect>    
                        <div className="mt-1">
                            <FilterMultipleSelect name_of_filtered="sub customer" selected={selectedSubCustomers} complete_object={subCustomers} onChange={(return_data) => setSelectedSubCustomers(return_data)}></FilterMultipleSelect>    
                        </div>
                        <div className="mt-1">
                            <FilterMultipleSelect name_of_filtered="user" selected={selectedUsers} complete_object={users} onChange={(return_data) => setSelectedUsers(return_data)}></FilterMultipleSelect>    
                        </div>
                        {/* <Form>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    onChange={setFilterActive(!filterActive)}
                                    label="Active"
                                    name="group1"
                                    type="switch"
                                    id={`inline-${type}-1`}
                                    checked
                                />
                                <Form.Check
                                    inline
                                    label="Unactive"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                />
                                </div>
                            ))}
                    </Form> */}
                    </Card.Header>
                    <Card.Body className="p-0 m-0">
                    {subCustomerAccess &&
                        <Table striped bordered className="m-0">
                            <thead>
                                <tr>
                                    {/* <th className="text-center fw-bold">ID</th> */}
                                    <th className="px-2 fw-bold">Customer</th>
                                    <th className="px-2 fw-bold">Sub Customer</th>
                                    <th className="text-center fw-bold">User</th>
                                    <th className="text-center fw-bold">User email</th>
                                    <th>Delete</th>
                          
                                </tr>
                            </thead>
                            <tbody>
                                {subCustomerAccess.filter(function (s) {
                                        if(selectedCustomers.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sc in selectedCustomers) {
                                                if (selectedCustomers[sc].id == s.sub_customer.customer.id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }).filter(function (s) {
                                        if(selectedSubCustomers.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sc in selectedSubCustomers) {
                                                if (selectedSubCustomers[sc].id == s.sub_customer.id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }).filter(function (s) {
                                        if(selectedUsers.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var u in selectedUsers) {
                                                if (selectedUsers[u].id == s.user.id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    }).map(i => {
                                    return(
                                        <tr key={i.id}>
                                            <td className="px-2 text-start">{i.sub_customer.customer.name}</td>
                                            <td className="px-2 text-start">{i.sub_customer.name}</td>
                                            <td className="px-2 text-start">{i.user.name}</td>
                                            <td className="px-2 text-start">{i.user.email}</td>
                                            <td className="d-flex justify-content-center"><Button onClick={() => handleDelete(i.id)} variant="danger">Delete</Button></td>
                                            {/* <td className="px-2">{i.customer[0].name}</td>
                                             <td className="px-2">{i.product[0].name}</td>
                                            <td className="text-center">{i.start_date}</td>
                                            <td className="px-2 text-end">{i.net_quantity_price}</td>
                                            <td className="text-center">{i.active == 1 && <Badge pill bg="success">Active</Badge>}{i.active == 0 && <Badge pill bg="secondary">Inactive</Badge>}</td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    </Card.Body>
                </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {selectedElement && selectedElementType == "SUBCUSTOMERACCESS" &&
                    <>
                        <label for="customer">Customer</label>
                        <input disabled id="customer" className="form-control" type="text" placeholder={selectedElement.customer[0].name} />
                        <label for="product">Product</label>
                        <input disabled id="product" className="form-control" type="text" placeholder={selectedElement.product[0].name} />
                        <label for="price">Net price</label>
                        <input disabled id="price" className="form-control" type="number" placeholder={selectedElement.net_quantity_price} />
                        <label for="date">Start date</label>
                        <input id="date" className="form-control" type="date" placeholder={selectedElement.start_date} />
                        
                        <label for="active">Status</label>
                        <input id="active" className="form-control" type="number" placeholder={selectedElement.active} />
                   
                    </>
                    }
                   
                    
                    {isCreateMode && selectedElementType == "SUBCUSTOMERACCESS" &&
                    <>
                        <label for="product">User(s)</label>
                        <FilterMultipleSelect name_of_filtered="user" selected={selectedCreateUsers} complete_object={users} onChange={(return_data) => setSelectedCreateUsers(return_data)}></FilterMultipleSelect>    

                        <label for="customer">Sub customer(s)</label>
                        <FilterMultipleSelect name_of_filtered="sub customer" selected={selectedCreateSubCustomers} complete_object={subCustomers} onChange={(return_data) => setSelectedCreateSubCustomers(return_data)}></FilterMultipleSelect>                            
                    </>
                    }
                    
                
                    {modalErrorMsg &&
                    <p className="text-danger">{modalErrorMsg}</p>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSend}>Save</Button>
                    </Modal.Footer>
            </Modal>
        
        </Row>
    </Container>   
                
    </AuthenticatedTemplate>
       </div>
    )
}