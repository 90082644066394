import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import { ButtonToolbar } from 'react-bootstrap';
import { useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';

 

export default function FilterMultipleOptions({filterByFields, filterByLabels, name_of_filtered, selected, complete_object, onChange}) {
  // const PublicMethodsExample = () => {
    const ref = useRef();
    // function update(list) {
    //   onChange(selected)
    // }

    // const [filterBy, setFilterBy] = useState('fields');
    // const filterByFields = ['name', 'article_nr', 'GTIN'];
  
  

    const placeholder = "Choose a ".concat(name_of_filtered)
    return (
      <>
      <link rel="stylesheet" href="https://unpkg.com/react-bootstrap-typeahead/css/Typeahead.css"/>
      <Form.Group>
        {/* <Form.Label>Select product</Form.Label> */}
        <InputGroup>
          <InputGroup.Text>{name_of_filtered.charAt(0).toUpperCase() + name_of_filtered.slice(1)}</InputGroup.Text>
        <Typeahead
          filterBy={filterByFields}
          id="public-methods-example"
          labelKey="name"
          options={complete_object}
          placeholder={placeholder}
          renderMenuItemChildren={(complete_object_i) => (
            <div>
              {complete_object_i.name}
              <div>
                <small>{filterByLabels[0]}: {complete_object_i[filterByFields[0]]}</small>
                <small> | </small>
                <small>{filterByLabels[1]} {complete_object_i[filterByFields[1]]}</small>
              </div>
            </div>
          )}
          ref={ref}
          onChange={(selected) => {
            onChange(selected)
          }}
          selected={selected}
        />
        </InputGroup>
        </Form.Group>
      </>
    );
}