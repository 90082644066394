import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: '573ef5ae-8687-464e-a232-f479fe262fb1',
        authority: 'https://login.microsoftonline.com/1386fe4f-8eb5-492c-92c1-390e76453a37',
        redirectUri: '/',
        postLogoutRedirectUri: '/', 
        //navigateToLoginRequestUrl: false, 
    },
    cache: {
        cacheLocation: 'sessionStorage', // "sessionStorage" - "localStorage" 
        //storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    }, 
  };

  /**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
  export const loginRequest = {
    scopes: ["User.Read"]
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMeOfficeLocation: "https://graph.microsoft.com/v1.0/me?$select=officeLocation",
    graphMeStreetAdress: "https://graph.microsoft.com/v1.0/me?$select=streetAddress",
    graphMeJobTitle: "https://graph.microsoft.com/v1.0/me?$select=jobTitle",
    graphMeDepartment: "https://graph.microsoft.com/v1.0/me?$select=department"
};

export const silentRequest = {
    scopes: ["openid", "profile", "User.Read"],
    loginHint: "fornavn.etternavn@fehmab.no"
};