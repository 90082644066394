


import { Spinner, Button} from "react-bootstrap";
import React from "react";

export default function StandardLoader() {
  return (
    <>
        <Button className="" variant="secondary" disabled>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            <span className="px-2">Loading...</span>
        </Button>
    </>
  );
};