import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import NavigationBar from "../modules/NavigationBar";

export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      {isAuthenticated ? <NavigationBar /> : ""}
      {props.children}
    </>
  );
};