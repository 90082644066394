import React from "react";
import { useEffect, useState } from "react";
import { Spinner, Accordion, Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import StandardLoader from "../../components/StandardLoader";

import { ListGroup, ListGroupItem } from "react-bootstrap";


import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import InventoryValueElement from "./InventoryValueElement";
import moment from "moment/moment";

export default function InventoryValue() {
    const [error, setError] = useState([]);

    
    const [filterActive, setFilterActive] = useState(true)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, SetProducts] = useState()    
    const [inventory, setInventory] = React.useState({});

    const [isLoading, setIsLoading] = useState(true);


    const [selectedElementType, SetSelectedElementType] = useState();

    const [yesterday, setYesterday] = useState(() => {
        let d = new Date();
        d.setDate(d.getDate()-1);
        return moment(d).format("YYYY-MM-DD");
    })

    const [InventoryDate, SetInventoryDate] = useState(yesterday);
    function handleDato(event){
        const {value} = event.target;
        SetInventoryDate(value);
        fetchInventoryValue(value);
    }

    


    const [InventoryValue, SetInventoryValue] = useState();
    function fetchInventoryValue(dateString){
        setIsLoading(true);
        fetch("./api/GetStockStatusAndDeliveriesForDate", {
            method: "POST",
            body: JSON.stringify(dateString),
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }),
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            console.log("OK");
            console.log(data)

            SetInventoryValue(data);
            setIsLoading(false);
        });
    }
    
    
    
    
    
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();

    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])
    
    React.useEffect(() => 
    {   
        if(!token) return;
        

        fetchInventoryValue(yesterday);

        fetch("./api/GetAllProducts",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
        })
        .then((data) => {
            SetProducts(data);
        });
    },[token]);

    
    
            
    return(
        
        <div>   
            <AuthenticatedTemplate>     
            <Container className="mt-5">
            
                <Row>
                <Col className="d-flex align-items-center">
                    <h2 className="my-0">Inventory value for {InventoryDate}</h2>
                </Col>
                <Col>
                <Card>
                        <Card.Header>Choose date</Card.Header>
                        {/* <Card.Body> */}
                            <ListGroup variant="flush">
                                <ListGroup.Item className="mt-2">
                                    {/* <div className="mb-2">Choose date to calculate value</div> */}
                                    <input onChange={handleDato} type="date" className="form-control" id="start" name="trip-start"
                                        value={InventoryDate}
                                        min={"2023-07-31"} 
                                        max={yesterday}                                     
                                    />
                                    <div className="mt-2"></div>
                                </ListGroup.Item>
                               
                            </ListGroup>
                        {/* </Card.Body> */}
                    </Card>
                </Col>
                </Row>
                {isLoading &&
                    <Row className="mt-4 align-items-center justify-content-space-between">
                            <Col className="d-flex align-items-center justify-content-center">
                                <StandardLoader />
                            </Col>
                    </Row>
                    
                }
                {InventoryValue && !isLoading &&
                <>
                <Row className="mt-4">
                    <Col>
                        <Card>
                            <Card.Header>Inventory value</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    Purchase price: NOK {InventoryValue.purchasePrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                </ListGroupItem>
                                <ListGroupItem>
                                    RÅK: NOK {InventoryValue.rakPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Transport: NOK {InventoryValue.transportPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Total value: NOK {InventoryValue.totalPrice.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})}
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Inventory information</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroupItem>
                                    Frozen products: {InventoryValue.frozen_products.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                </ListGroupItem>
                                
                                <ListGroupItem>
                                    Dry products: {InventoryValue.dry_products.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                </ListGroupItem>
                                
                                <ListGroupItem>
                                    # of cartons: {InventoryValue.number_of_cartons.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                </ListGroupItem>
                                <ListGroupItem>
                                    # of pallets: {InventoryValue.number_of_pallets.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}
                                </ListGroupItem>
                                
                                
                            </ListGroup>
                        </Card>
                    </Col>
                    
                </Row>
                <Row className="mt-4">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Frozen products lacking static data ({ InventoryValue.frozen_products_lacking_static_data.length })</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Art. nr.</th>
                                            <th>Name</th>
                                            <th>RÅK / kilo</th>
                                            <th>Net weight / unit</th>
                                            <th>Transport cost / pallet</th>
                                            <th>Cartons / pallet, inbound</th>
                                            <th>Units / carton</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {InventoryValue.frozen_products_lacking_static_data.map(item => {
                                            return(
                                                <tr>
                                                    <td>{item.product.article_nr}</td>
                                                    <td>{item.product.backstube_name}</td>
                                                    <td>{item.product.råK_per_kilo}</td>
                                                    <td>{item.product.net_weight}</td>
                                                    <td>{item.product.transport_cost_per_pallet}</td>
                                                    <td>{item.product.cartons_per_pallet_inbound}</td>
                                                    <td>{item.product.pieces_per_quantity}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                        
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Dry products lacking static data ({ InventoryValue.dry_products_lacking_static_data.length })</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Art. nr.</th>
                                            <th>Name</th>
                                            <th>Transport cost / pallet</th>
                                            <th>Cartons / pallet, inbound</th>
                                            <th>Units / carton</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {InventoryValue.dry_products_lacking_static_data.map(item => {
                                            return(
                                                <tr>
                                                    <td>{item.product.article_nr}</td>
                                                    <td>{item.product.backstube_name}</td>
                                                    <td>{item.product.transport_cost_per_pallet}</td>
                                                    <td>{item.product.cartons_per_pallet_inbound}</td>
                                                    <td>{item.product.pieces_per_quantity}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                        
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Delivery lines lacking data ({ InventoryValue.deliveries_lacking_data.length })</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Art. nr.</th>
                                            <th>Name</th>
                                            <th>Delivery ID</th>
                                            <th>Delivery Line ID</th>
                                            <th>Price</th>
                                            <th>Currency</th>
                                            <th>Invoice date</th>
                                            <th>Invoice voucher</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        InventoryValue.deliveries_lacking_data.map((item) => (
                                            item.statuses.map((status, index) => (
                                            <tr key={index}>
                                                <td>{item.product.article_nr}</td>
                                                <td>{item.product.backstube_name}</td>
                                                <td>{status.delivery.id}</td>
                                                <td>{status.delivery_line.id}</td>
                                                <td>{status.delivery_line.price}</td>
                                                <td>{status.delivery_line.currency}</td>
                                                <td>{status.delivery_line.invoice_date}</td>
                                                <td>{status.delivery_line.invoice_voucher}</td>
                                            </tr>
                                            ))
                                        ))
                                        }
                                    </tbody>
                                </Table>
                        
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                
                <Row className="mt-4">
                <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)}></FilterMultipleSelect>                        
                    </Card.Header> 
                    <Card.Body className="p-0 m-0">
                        <Table striped bordered hover responsive className="m-0">
                            <thead>
                                <tr>
                                    <th className="text-start px-2 fw-bold">Name</th>
                                    <th>Cartons, {InventoryDate}</th>
                                    <th>Pallets, {InventoryDate}</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Purchase price</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">RÅK</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Transport</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Total value</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Avg. value / carton</th>
                                    {/* <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Transport cost / pallet</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Cartons / pallet</th>
                                    <th style={{whiteSpace: 'nowrap'}} className="px-2 fw-bold">Transport cost / carton</th> */}
                                </tr>
                            </thead>
                            <tbody style={{"cursor": "pointer"}}>
                                {InventoryValue.inventoryValue.filter(function (s) {
                                        if(selectedProducts.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var sp in selectedProducts) {
                                                if (selectedProducts[sp].id == s.product.id) {
                                                    return true
                                                }
                                            }
                                            return false
                                        }
                                    })
                                .map(i => {
                                    return(
                                        <InventoryValueElement i={i} key={i.product.article_nr}/>
                                    )
                                })}
                            </tbody>
                        </Table>
                    
                    </Card.Body>
                </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                
        
                </Row>
                </>
                }
            </Container>   
                
            </AuthenticatedTemplate>    
       </div>
    )
}