import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";

export default function FileNotFound() {

    return(
        <div className="container-fluid">
            <div className="container d-flex justify-content-center align-items-center flex-column vh-100">
                <img src={logo} alt="" />
                <h1>404 - Page not found.</h1>
                <p>Looks like something went wrong.</p>
                <NavLink className="btn btn-secondary" to="/">Go back</NavLink>
            </div>
        </div>
        
    )
}