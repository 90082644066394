import OrderListElement from "./OrderListElement";
import { useState, useEffect, useRef } from "react";
import NavigationBar from "../../modules/NavigationBar";
import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import { ThreeDots } from "react-loader-spinner";

export default function MyOrders() {
    const [orders, setOrders] = useState([]);
    const [token, SetToken] = useState();
    const { instance, accounts } = useMsal();
    const [perPage, setPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const timeoutRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [maxPage, SetMaxPage] = useState(false);

    
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])
    

    const getOrderSegment = async () => {
        if(!token) return;
        setLoading(true);
        SetMaxPage(false);
        await fetch("./api/GetOrdersForUser", {
            method: "POST",
            body: JSON.stringify({
                "page": currentPage,
                "pageSize": perPage
            }),
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            })
        })
        .then(res => res.json())
        .then(data => {
            setOrders(data);
            setLoading(false);
            SetMaxPage(false);
        })
        .catch(err => {
            console.log("error ran")
            console.log(err);
            SetMaxPage(true);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(!token) return;
        getOrderSegment();      
    },[token])

    useEffect(() => {
        console.log(orders.sort((a,b) => a.id - b.id)[orders.length-1]);

    }, [orders])


    const nextPage = () => {
        if(orders.length > 0) setCurrentPage(currentPage+1);
    }

    const previousPage = () => {
        if(currentPage > 0) setCurrentPage(currentPage-1);
    }

    const backToBeginning = () => {
        setCurrentPage(0);
    }

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            getOrderSegment();
            // Clear the timeout ref
            timeoutRef.current = null;
        }, 500);

        return () => {
            if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current);
            }
        };
    }, [currentPage])

    

    return(
        <>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                    <h2>{/*auth.user.customer*/}Order history</h2>
                </Row>
            </Container>
            <Container className="">
                <div className="col-5">
                    <button className="btn btn-dark m-1 col-3" onClick={backToBeginning}>To start</button>
                    <button className="btn btn-dark m-1 col-3" onClick={previousPage}>Previous</button>
                    <button className="btn btn-dark m-1 col-3" onClick={nextPage}>Next</button>
                    <button className="btn btn-dark m-2 disabled">Page: {currentPage+1}</button>
                </div>
                        {loading &&
                            <div className="d-flex justify-content-center align-items-center">
                                <ThreeDots
                                height="80" 
                                width="80" 
                                radius="9"
                                color="#3e3e3e" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                                />
                            </div>
                        }
                <Row>
                {!loading && !maxPage &&
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">Order ID</th>
                                <th className="text-center">Order date</th>
                                <th className="text-center">Pick-up date</th>
                                <th className="px-2">Customer</th>
                                <th className="px-2">Sub Customer</th>
                                <th className="text-center"># Order Lines</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {
                            orders.sort((a, b) => b.id - a.id).map((o) => {
                                return <OrderListElement o={o} Key={o.id} />
                            })
                            
                            }
                        </tbody>
                    </Table>
                }
                {maxPage &&
                <p>You have reached the end of the list.</p>
                }
                </Row>
                
             </Container>
             </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
                Ingen tilgang
             </UnauthenticatedTemplate>
               
                
                    

              
        </>
    )
}