import React from "react";
import { useEffect, useState } from "react";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import StandardLoader from "../../components/StandardLoader";

import FilterMultipleWithLabels from "../../components/FilterMultipleWithLabels";


import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";

export default function Inventory() {
    const [error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const filterByFields = ['backstube_name', 'article_nr', 'gtin'];
    const filterByLabels = ['Name', 'Article nr.', 'gtin'];
    const [selectedProducts, setSelectedProducts] = useState([])

    

    const [filterActive, setFilterActive] = useState(true)
    const [products, SetProducts] = useState()    
    const [inventory, setInventory] = React.useState({});
    const [selectedElementType, SetSelectedElementType] = useState();
    
    
    const [toastMessage, SetToastMessage] = useState("");
    const [toastIsError, SetToastIsError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const handleToastClose = () => {
        setShowToast(!showToast);
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalErrorMsg, SetModalErrorMsg] = useState();

    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])
    
    React.useEffect(() => 
    {
        setIsLoading(true);

        if(!token) return;

        fetch("./api/GetAllProducts",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if(response.status == 200) {
                return response.json();
            }
           
        })
        .then((data) => {
            console.log("OK");
            SetProducts(data);
            console.log(data);
        });
        
        fetch("./api/getInventoryLast14Days",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then((response) => {
            if (response.ok){
                return response.json();                    
            }
        })
        .then((dataServer) => {
            setInventory(dataServer);
            console.log(dataServer);
            setIsLoading(false);

        })        
    },[token]); 
            
    return(
        
        <div>   
            <AuthenticatedTemplate>     
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex align-items-center">
                        <h2 className="my-0">Inventory</h2>
                    </Col>
                </Row>
                {isLoading &&
                    <Row className="mt-4 align-items-center justify-content-space-between">
                            <Col className="d-flex align-items-center justify-content-center">
                                <StandardLoader />
                            </Col>
                    </Row>
                    
                }
                {!isLoading &&
                    <Row className="mt-4">

                    <Card className="mb-3 p-0 col-12">
                        <Card.Header>
                            <FilterMultipleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)} />

                        </Card.Header> 
                        <Card.Body className="p-0 m-0">
                        {inventory && inventory.dates &&
                            <Table striped bordered hover responsive className="m-0">
                                <thead>
                                    <tr>
                                    <th className="fw-bold text-center px-2">Art. #</th>

                                        <th className="text-start px-2 fw-bold">Name</th>
                                        {inventory.dates.map(d => {
                                                    return <th style={{whiteSpace: 'nowrap'}} className="w-auto text-center px-2 fw-bold">{d}</th>
                                        })}                                    
                                    </tr>
                                </thead>
                                <tbody style={{"cursor": "pointer"}}>
                                    {inventory.inventory_history.filter(item => {
                                        if (selectedProducts.length == 0){
                                            return true;
                                        }
                                        else {
                                            for (var p in selectedProducts){
                                                if (selectedProducts[p].id == item.product_id){                                                
                                                    return true;
                                                }                                            
                                            }
                                            return false;                     
                                        }
                                    }).map(prod => {
                                        return(
                                            <tr key={prod.product_id}>
                                                <td className="px-2 text-center">{prod.article_nr}</td>

                                                <td className="px-2">{prod.product_name}</td>
                                                
                                                {
                                                    prod.inventory_data.map(pi => {
                                                        return(
                                                            <td className="px-2 text-end">{ pi.quantity }</td>
                                                        )
                                                    })
                                                }                                            
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        }
                        </Card.Body>
                    </Card>


                <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast show={showToast} onClose={handleToastClose}>
                        <Toast.Header>
                        <strong className="me-auto">Backstube</strong>
                        <small>now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <p className={toastIsError ? "text-danger" : ""}>{toastMessage}</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                
        
                </Row>
                }
                
            </Container>   
                
            </AuthenticatedTemplate>    
       </div>
    )
}